import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Button from '../button';

import { useTranslation } from 'react-i18next';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Context from '../../context';
import { onClickSave } from '../../tools';
import Level from './components/level';
import GlobalOptions from './components/global_options';

const AuthOptionsModal = React.memo(() => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const {
        showAuthOptionsModal,
        AuthControlData,
        pageId,
        constructor,
        windowDimensions,
        pageTitle,
        token,
        showAuthParams,
        pathToFiles,
        changedPages,
        palette,
    } = state;

    const [layersCount, setLayersCount] = useState(1);
    const authOptions = constructor && constructor.authParams ? constructor.authParams : undefined;

    useEffect(() => {
        if (authOptions) {
            const length = constructor.authParams ? constructor.authParams.layers.length : 1;
            setLayersCount(length);
        }
    }, [authOptions]);

    const closeModal = () => {
        dispatch({
            type: 'toggleAuthOptionsModal',
            payload: { show: false },
        });
    };

    const handleChange = (e, value) => {
        e.stopPropagation();
        dispatch({
            type: 'onChangeLayersCount',
            payload: { count: Number(value) + 1 },
        });
    };

    const onContainerClick = (e) => {
        e.stopPropagation();
    };

    const tabStyle = {
        backgroundColor: palette.themePrimary,
        color: '#eee',
    };

    const saveButtonStyle = {
        width: 250,
        height: 50,
        marginLeft: 'auto',
        marginTop: 10,
    };

    const onToggle = (level, auth_title, radio, isChecked) => {
        dispatch({
            type: 'onToggleLevelSwitch',
            payload: { level, auth_title, radio, isChecked },
        });
    };

    const onSaveAuthModalClick = () => {
        // dispatch({
        //     type: 'onSaveAuthModalClick'
        // });
        onClickSave(pathToFiles, pageTitle, dispatch, token, constructor, pageId, showAuthParams);
        closeModal();
    };

    const count = AuthControlData[pageId] ? Number(AuthControlData[pageId].layersCount) : 1;
    const disabled = changedPages[pageId] ? changedPages[pageId] : false;

    return (
        AuthControlData[pageId] && (
            <Overlay show={showAuthOptionsModal}>
                <Container width={1200} height={800} onClick={onContainerClick} windowDimensions={windowDimensions}>
                    <Title palette={palette}>{t('Уровни авторизации')}</Title>
                    <AppBar position="static" color="default">
                        <Tabs value={count - 1} onChange={handleChange} textColor="primary" variant="fullWidth" scrollButtons="auto">
                            <Tab style={tabStyle} label={t('Один уровень')} />
                            <Tab style={tabStyle} label={t('Два уровня')} />
                            <Tab style={tabStyle} label={t('Три уровня')} />
                        </Tabs>
                    </AppBar>
                    {count === 1 && (
                        <TabContainer>
                            <Level level={1} onToggle={onToggle} />
                        </TabContainer>
                    )}
                    {count === 2 && (
                        <TabContainer>
                            <Level level={1} onToggle={onToggle} />
                            <Level level={2} onToggle={onToggle} />
                        </TabContainer>
                    )}
                    {count === 3 && (
                        <TabContainer>
                            <Level level={1} onToggle={onToggle} />
                            <Level level={2} onToggle={onToggle} />
                            <Level level={3} onToggle={onToggle} />
                        </TabContainer>
                    )}
                    <Footer>
                        <GlobalOptions />
                        <ButtonsCont>
                            <Button style={saveButtonStyle} variant="outlined" size="medium" color="secondary" onClick={closeModal}>
                                {t('Закрыть без сохранения')}
                            </Button>
                            <Button
                                margin={'10px 0 0 0'}
                                style={saveButtonStyle}
                                variant="contained"
                                size="medium"
                                color="primary"
                                onClick={onSaveAuthModalClick}
                            >
                                {t('Сохранить изменения')}
                            </Button>
                        </ButtonsCont>
                    </Footer>
                </Container>
            </Overlay>
        )
    );
});

export default AuthOptionsModal;

const ButtonsCont = styled.div`
    display: flex;
    flex-direction: column;
`;
const Footer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    align-items: flex-end;
`;
const Title = styled.h2`
    display: flex;
    width: 100%;
    color: ${(p) => p.palette.themePrimary};
    font-family: 'Open Sans';
    justify-content: center;
`;

const TabContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5%;
    width: 100%;
    overflow: auto;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display: ${(p) => (p.show ? 'block' : 'none')};
`;
const Container = styled.div`
    padding: 5px;
    position: absolute;
    left: 50%;
    top: 50px;
    margin-left: ${(p) => -p.width / 2 + 'px'};
    width: ${(p) => p.width + 'px'};
    max-height: ${(p) => p.windowDimensions.height - 100 + 'px'};
    background-color: whitesmoke;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
`;
