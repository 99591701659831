import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import Context from '../../../context';
import CollapseSection from './collapse_section';
import MediaPicker from './media_picker';
import TextEditor from './text_editor';
import Paddings from './paddings';
import WebLink from './web_link';

const BlockParams = React.memo(({ name, id, children }) => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { selectedBlock, constructor, pathToFiles } = state;
    const { accordion } = state.blockParams;

    if (!selectedBlock)
        return (
            <Container>
                <p>{t('Выберите блок')}</p>
            </Container>
        );
    let backgroundColor = '#fff';
    if (constructor?.blocks[selectedBlock.block]) {
        try {
            backgroundColor =
                selectedBlock.subBlock === undefined
                    ? constructor.blocks[selectedBlock.block].backgroundColor
                    : constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock].backgroundColor;
        } catch (error) {console.warn(error)}
    }

    let backgroundImgUrl = '';
    let BGSize = 'cover';
    let textParams;
    let style;
    let url = 'http://';

    const handleChangeBlockBGColor = color => {
        dispatch({
            type: 'handleChangeBlockBGColor',
            payload: { bgColor: color.rgb, selectedBlock }
        });
    };

    const onImgContainerClick = item => {
        dispatch({
            type: 'changeBlockBgImage',
            payload: { item, selectedBlock }
        });
    };

    const onDeleteImageButtonClick = () => {
        dispatch({
            type: 'onDeleteImageButtonClick',
            payload: { selectedBlock }
        });
    };

    const onContainCheckboxChange = e => {
        dispatch({
            type: 'onBlockContainCheckboxChange',
            payload: { checked: e.target.checked, selectedBlock }
        });
    };

    const toggle = id => {
        dispatch({
            type: 'toggleBlockParamsCollapse',
            payload: id
        });
    };
    let block;
    let subBlock;
    try {
        block = constructor.blocks[selectedBlock.block];
        subBlock = constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
    } catch (error) {
        console.log(error);
    }

    if (block && selectedBlock.subBlock === undefined) {
        if (block && block.media && block.media[0]) {
            backgroundImgUrl = block.media[0].url;
            BGSize = block.media[0].BGSize;
        }
        if (block && block.textParams) {
            textParams = { ...block.textParams };
        }
        if (block && block.style) {
            style = { ...block.style };
        }
        if (block && block.params.url) {
            url = block.params.url;
        }
    } else {
        if (subBlock && subBlock.media && subBlock.media[0]) {
            backgroundImgUrl = subBlock.media[0].url;
            BGSize = subBlock.media[0].BGSize;
        }
        if (subBlock && subBlock.textParams) {
            textParams = { ...subBlock.textParams };
        }
        if (subBlock && subBlock.style) {
            style = { ...subBlock.style };
        }
        if (subBlock && subBlock.params && subBlock.params.url) {
            url = subBlock.params.url;
        }
    }

    const isContainChecked = BGSize === 'contain';

    return selectedBlock ? (
        <Container>
            <CollapseSection id={1} isOpen={accordion[1]} toggle={toggle} name={t('Текст блока')}>
                <TextEditor textParams={textParams} />
            </CollapseSection>
            <CollapseSection id={2} isOpen={accordion[2]} toggle={toggle} name={t('Цвет фона')}>
                <SketchPicker color={backgroundColor} onChangeComplete={handleChangeBlockBGColor} />
            </CollapseSection>
            <CollapseSection id={3} isOpen={accordion[3]} toggle={toggle} name={t('Изображение')}>
                <MediaPicker
                    currentImg={`${pathToFiles}${backgroundImgUrl}`}
                    onImgContainerClick={onImgContainerClick}
                    isContainChecked={isContainChecked}
                    onContainCheckboxChange={onContainCheckboxChange}
                    onDeleteImageButtonClick={onDeleteImageButtonClick}
                />
            </CollapseSection>
            <CollapseSection id={4} isOpen={accordion[4]} toggle={toggle} name={t('Отступы')}>
                <Paddings style={style} />
            </CollapseSection>
            <CollapseSection id={5} isOpen={accordion[5]} toggle={toggle} name={t('WEB-Ссылка')}>
                <WebLink url={url} />
            </CollapseSection>
        </Container>
    ) : null;
});

export default BlockParams;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    p {
        height: 30px;
    }
`;

const Title = styled.h4`
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-top: ${props => (props.marginTop ? props.marginTop + 'px' : 0)}
    margin-bottom: 10px;
    border-bottom: 1px solid #777;
    display: flex;
    width: 100%;
    align-items: center;
`;
