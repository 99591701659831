import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import Context from '../../../context';

import LevelTitle from './level_title';
import Switch from './switch';
import CommonOptions from './common_options';
import VkontakteOptions from './vkontakte_options';
import FacebookOptions from './facebook_options';
import PasswordOptions from './password_options';
import ExternalOptions from './external_options';

const Level = React.memo(({ level, onToggle }) => {
    const { t, i18n } = useTranslation();
    const { state } = useContext(Context);
    const { pageId, AuthControlData, palette } = state;

    const options = item => {
        switch (item.auth_title) {
            case 'sms':
                return (
                    <CommonOptions
                        auth_title={item.auth_title}
                        label={t('запомнить на')}
                        value={item.options.expiration_sms_period}
                        level={level}
                    />
                );
            case 'call':
                return (
                    <CommonOptions
                        auth_title={item.auth_title}
                        label={t('запомнить звонок на')}
                        value={item.options.expiration_call_period}
                        level={level}
                    />
                );
            case 'vkontakte':
                return <VkontakteOptions auth_title={item.auth_title} options={item.options} level={level} />;
            case 'facebook':
                return <FacebookOptions auth_title={item.auth_title} options={item.options} level={level} />;
            case 'password':
                return <PasswordOptions auth_title={item.auth_title} options={item.options} level={level} />;
            case 'external':
                return <ExternalOptions auth_title={item.auth_title} options={item.options} level={level} />;

            default:
                return null;
        }
    };

    return (
        <Container>
            <LevelTitle palette={palette}>{`${t('Уровень авторизации')} ${level}`}</LevelTitle>
            {AuthControlData[pageId] &&
                AuthControlData[pageId][level - 1].map(item => {
                    return (
                        <Switch
                            key={`${item.auth_title}-${level}`}
                            auth_title={item.auth_title}
                            title={item.title}
                            checked={item.checked}
                            disabled={item.disabled}
                            level={level}
                            radio={item.radio}
                            onToggle={onToggle}
                        >
                            {item.checked && options(item)}
                        </Switch>
                    );
                })}
        </Container>
    );
});

export default Level;

const Container = styled.div`
    width: 100%;
    padding: 5px;
    background-color: whitesmoke;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
`;
