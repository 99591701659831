import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';

const Pages = React.memo(({ name, id, children }) => {
    const { state, dispatch } = useContext(Context);
    const { pages } = state;

    const onPageClick = (pageId, pageTitle, pageUUID) => e => {
        const currentPageId = state.pageId;
        dispatch({
            type: 'setCurrentPage',
            payload: { pageId, currentPageId, pageTitle, pageUUID }
        });
    };

    return (
        <Ul>
            {pages.map((item, i) => {
                const showBorder = item.id === state.pageId;
                return (
                    <Li
                        showBorder={showBorder}
                        key={`${item.id}-${item.title}`}
                        onClick={onPageClick(item.id, item.title, item.uuid)}
                    >
                        <PageTitle>{item.title}</PageTitle>
                        <Img width={75} src={item.preview} />
                    </Li>
                );
            })}
        </Ul>
    );
});

export default Pages;

const PageTitle = styled.p`
    padding: 5px;
    margin: 0;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
`;

const Img = styled.img`
    width: 100%;
    object-fit: cover;
    object-position: 0 0;
`;

const Ul = styled.ul`
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0px;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 5px;
`;

const Li = styled.li`
    border-width: 4px;
    border-style: solid;
    border-color: ${p => (p.showBorder ? 'rgb(242,16,90)' : 'transparent')};
    cursor: pointer;
    justify-self: center;
    width: 75px;
    height: 112px;
    display: flex;
    flex-shrink: 0;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    position: relative;
`;
