import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import CircularProgress from '@material-ui/core/CircularProgress';
import Context from '../context';

const SpinnerModal = React.memo(() => {
    const { state, dispatch } = useContext(Context);
    const { showSpinner } = state;

    return (
        <Overlay show={showSpinner}>
            <CircularProgress size={80} color="secondary" />
        </Overlay>
    );
});

export default SpinnerModal;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 30;
    display: ${p => (p.show ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
`;
