import React from 'react';
import Button from '@material-ui/core/Button';
import styled from 'styled-components/macro';
import Context from '../context';

export default React.memo(({ variant, color, fullWidth, margin = 0, ...props }) => {
    const { state } = React.useContext(Context);
    const { palette } = state;

    const colors = () => {
        if (variant !== 'outlined') {
            if (color === 'primary' || color === 'secondary') {
                return {
                    bgColor: palette?.themePrimary || '#1E90FF',
                    hoveredBgColor: palette?.themeDark || '#0066cc',
                    textColor: '#fff',
                    borderColor: null,
                };
            }
        } else {
            return {
                bgColor: '#fff' || '#eee',
                hoveredBgColor: palette?.neutralLight || '#ddd',
                textColor: palette?.themePrimary || '#1E90FF',
                borderColor: palette?.themePrimary || '#1E90FF',
            };
        }
    };

    return (
        <StyledButton colors={colors()} margin={margin} variant={variant} color={color} fullWidth={fullWidth} onClick={props.onClick}>
            {props.children}
        </StyledButton>
    );
});

const StyledButton = styled(Button)`
    margin: ${(p) => p.margin + '!important'};
    background-color: ${(p) => p.colors.bgColor + ' !important'};
    :hover {
        background-color: ${(p) => p.colors.hoveredBgColor + ' !important'};
    }
    border: ${(p) => (p.colors.borderColor ? `1px solid ${p.colors.borderColor} !important` : 'none')};
    span {
        color: ${(p) => p.colors.textColor};
    }
`;
