import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Context from '../../../context';
import purple from '@material-ui/core/colors/purple';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '../../switch';
import TextField from '@material-ui/core/TextField';
import Button from '../../button';

import SmallColorPicker from './small_color_picker';

const AuthParams = React.memo(() => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { constructor } = state;


    const onChangeAuthButtonBackgroundColor = (formatType, authButtonBackgroundColor, selectedBlock) => {
        dispatch({
            type: 'onChangeAuthButtonBackgroundColor',
            payload: { authButtonBackgroundColor },
        });
    };

    const onChangeAuthBackgroundColor = (formatType, authBackgroundColor, selectedBlock) => {
        dispatch({
            type: 'onChangeAuthBackgroundColor',
            payload: { authBackgroundColor },
        });
    };

    const onInternetButtonTextChange = (e) => {
        dispatch({
            type: 'onInternetButtonTextChange',
            payload: { authButtonText: e.target.value },
        });
    };

    const onToggleAuthLandingShow = () => {
        dispatch({
            type: 'onToggleAuthLandingShow',
        });
    };

    const handleShowAuthOptionsModal = () => {
        dispatch({
            type: 'toggleAuthOptionsModal',
            payload: { show: true },
        });
    };

    const backButtonColor = constructor.authButtonBackgroundColor || 'rgba(51, 71, 80, 0.9)';
    const backPageColor = constructor.authBackgroundColor || 'rgba(51, 71, 80, 0.9)';
    const text = constructor.authButtonText || 'ВОЙТИ В ИНТЕРНЕТ';
    const showLanding = constructor.authDisplayMode || false;

    return (
        <Container>
            <InnerContainer>
                <p>{t("Фон кнопки 'Войти в интернет'")}</p>
                <SmallColorPicker width={'20%'} color={backButtonColor} onChangeColor={onChangeAuthButtonBackgroundColor} />
            </InnerContainer>
            <InnerContainer>
                <p>{t('Фон панели авторизации')}</p>
                <SmallColorPicker width={'20%'} color={backPageColor} onChangeColor={onChangeAuthBackgroundColor} />
            </InnerContainer>
            <InnerContainer>
                <TextField
                    key={'ddd-vdsvvbwe'}
                    id="outlined-multiline-flexible"
                    label={t('Текст кнопки')}
                    fullWidth
                    value={text}
                    onChange={onInternetButtonTextChange}
                    margin="normal"
                    variant="outlined"
                />
            </InnerContainer>
            <InnerContainer>
                <p>{t('Пропускать отображение лэндинга (сразу переходить к авторизации)')}</p>
                <FormControlLabel
                    style={{ marginRight: -8, marginLeft: 10 }}
                    control={
                        <Switch  checked={showLanding} onChange={onToggleAuthLandingShow} value="onToggleAuthLandingShow" />
                    }
                />
            </InnerContainer>
            <InnerContainer>
                <Button variant="contained" color="primary" fullWidth onClick={handleShowAuthOptionsModal}>
                    {t('Параметры авторизации')}
                </Button>
            </InnerContainer>
        </Container>
    );
});

export default AuthParams;

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    p {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 75%;
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    overflow: hidden;
`;
