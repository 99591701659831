import React, { useContext, useMemo } from 'react';
import Context from '../../context';
import CollapseSection from './components/collapse_section';
import Pages from './components/pages';
import PageParams from './components/page_params';
import BlockParams from './components/block_params';
import AddBlock from './components/add_block';
import Auth from './components/auth';

import { useTranslation } from 'react-i18next';

const ControlPanel = React.memo(() => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { accordion } = state.controlPanel;
    const { pages } = state;

    const toggle = id => {
        dispatch({
            type: 'toggleCollapse',
            payload: id
        });
    };

    return useMemo(
        () => (
            <div>
                <CollapseSection id={1} isOpen={accordion[1]} toggle={toggle} isMain={true} name={t('Сохраненные страницы')}>
                    <Pages />
                </CollapseSection>
                <CollapseSection id={2} isOpen={accordion[2]} toggle={toggle} isMain={true} name={t('Параметры страницы')}>
                    <PageParams />
                </CollapseSection>
                <CollapseSection id={3} isOpen={accordion[3]} toggle={toggle} isMain={true} name={t('Добавить блок (ширина х высота)')}>
                    <AddBlock />
                </CollapseSection>
                <CollapseSection id={4} isOpen={accordion[4]} toggle={toggle} isMain={true} name={t('Параметры блока')}>
                    <BlockParams />
                </CollapseSection>
                <CollapseSection id={5} isOpen={accordion[5]} toggle={toggle} isMain={true} name={t('Параметры авторизации')}>
                    <Auth />
                </CollapseSection>
            </div>
        ),
        [accordion, toggle]
    );
});

export default ControlPanel;
