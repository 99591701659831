import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import Context from '../../../context';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';

const CommonOptions = React.memo(({ label, auth_title, value, level, ...props }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const [units, setUnits] = React.useState('days');
    const [maxNumber, setMaxNumber] = React.useState(180);
    const [divider, setDivider] = React.useState(24 * 3600);

    useEffect(() => {
        if (units === 'days') {
            setMaxNumber(() => 180);
            setDivider(() => 24 * 3600);
        } else {
            setMaxNumber(() => 180 * 24);
            setDivider(() => 3600);
        }
    }, [units]);

    const { pageId } = state;

    const handleChange = (e) => {
        const value = Number(e.target.value) * divider;
        const options = {
            [`expiration_${auth_title}_period`]: value,
        };
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options },
        });
    };

    const handleUnitsChange = (event) => {
        setUnits(event.target.value);
    };

    return (
        <CollapsedContainer>
            <InputContainer>
                <span>{label}</span>
                <Input
                    type="number"
                    inputProps={{ min: 0, max: maxNumber }}
                    value={value / divider}
                    disableUnderline
                    onChange={handleChange}
                />
                <StyledFormControl variant="outlined">
                    <Select labelId="demo-simple-select-label" id="demo-simple-select" value={units} onChange={handleUnitsChange}>
                        <MenuItem value={'hours'}>{t('Часы')}</MenuItem>
                        <MenuItem value={'days'}>{t('Дни')}</MenuItem>
                    </Select>
                </StyledFormControl>
            </InputContainer>
        </CollapsedContainer>
    );
});

export default CommonOptions;

const StyledFormControl = styled(FormControl)`
    justify-content: center;
    div {
        padding: 2px 5px;
        font-size: 14px;
    }
`;

const CollapsedContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const InputContainer = React.memo(styled.div`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: grid;
    grid-template-columns: 9fr 4fr 5fr;
    grid-gap: 5px;
    margin: 0 10px 5px 20px;
    padding-right: 10px;
    padding-left: 10px;
    span {
        display: flex;
        align-items: center;
    }
`);
