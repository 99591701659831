import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import { isIndexesEqual } from '../../../tools';

import BlockControl from './block_control';

const Block = React.memo(
    ({ interactive, marginBottom, prototype, parentPrototype, position, border, size, block, children, index, isPreview, zIndex }) => {
        const { state, dispatch } = useContext(Context);
        const { constructorSize, selectedBlock, constructor } = state;

        const onConstructorBlockClick = (index) => (e) => {
            e.stopPropagation();
            !isPreview &&
                dispatch({
                    type: 'onConstructorBlockClick',
                    payload: index,
                });
        };

        const dimBlock = () => {
            if (!state.selectedBlock) return false;
            if (isIndexesEqual(state.selectedBlock, index)) {
                return false;
            } else {
                return true;
            }
        };

        const href = isPreview && block.params && block.params.url ? block.params.url : undefined;

        let blockWidth = constructorSize.width;
        if (block?.prototype) {
            blockWidth = block.prototype.rel_width
                ? block.prototype.rel_width * constructorSize.width
                : block.prototype.width * constructorSize.width;
        }

        return useMemo(
            () =>
                href && href !== 'http://' ? (
                    <ContainerLink
                        href={href}
                        target={'_blank'}
                        prototype={prototype}
                        parentPrototype={parentPrototype}
                        dimBlock={dimBlock()}
                        onClick={onConstructorBlockClick(index)}
                        interactive={interactive}
                        marginBottom={marginBottom}
                        border={border}
                        position={position}
                        size={size}
                        block={block}
                        zIndex={zIndex}
                        pathToFiles={state.pathToFiles}
                        isPreview={isPreview}
                        constructorSize={constructorSize}
                        selectedBlock={selectedBlock}
                    >
                        {block && block.textParams && (
                            <Text textParams={block.textParams} blockWidth={blockWidth}>
                                <pre>{block.textParams.textValue}</pre>
                            </Text>
                        )}
                        {children}
                        {!children && !interactive && <span>{size.ratio}</span>}
                        <BlockControl dimBlock={dimBlock()} position={position} interactive={interactive} isPreview={isPreview} />
                    </ContainerLink>
                ) : (
                    <Container
                        target={'_blank'}
                        prototype={prototype}
                        parentPrototype={parentPrototype}
                        dimBlock={dimBlock()}
                        onClick={onConstructorBlockClick(index)}
                        interactive={interactive}
                        marginBottom={marginBottom}
                        border={border}
                        position={position}
                        size={size}
                        block={block}
                        zIndex={zIndex}
                        pathToFiles={state.pathToFiles}
                        isPreview={isPreview}
                        constructorSize={constructorSize}
                        selectedBlock={selectedBlock}
                    >
                        {block && block.textParams && (
                            <Text textParams={block.textParams} blockWidth={blockWidth}>
                                <pre>{block.textParams.textValue}</pre>
                            </Text>
                        )}
                        {children}
                        {!children && !interactive && <span>{size.ratio}</span>}
                        <BlockControl dimBlock={dimBlock()} position={position} interactive={interactive} isPreview={isPreview} />
                    </Container>
                ),
            [
                block,
                border,
                children,
                constructorSize,
                dimBlock,
                href,
                index,
                interactive,
                isPreview,
                marginBottom,
                onConstructorBlockClick,
                parentPrototype,
                position,
                prototype,
                selectedBlock,
                size,
                state.pathToFiles,
                zIndex,
            ]
        );
    }
);

export default Block;

const Text = styled.div.attrs(({ textParams }) => {
    let alignItems = 'center';
    const va = textParams.verticalAlign;
    if (va === 'bottom') {
        alignItems = 'flex-end';
    } else if (va === 'top') {
        alignItems = 'flex-start';
    }
    return {
        alignItems,
    };
})`
    width: 100%;
    padding: 5px;
    margin: 0;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: ${(props) => props.alignItems};
    pre {
        color: ${(p) => p.textParams.color};
        font-family: ${(p) => p.textParams.font};
        font-size: ${(p) => Number((p.textParams.fontSize * p.blockWidth) / 200) + 'px'};
        font-style: ${(p) => p.textParams.fontStyle};
        font-weight: ${(p) => p.textParams.fontWeight};
        text-decoration: ${(p) => p.textParams.textDecoration};
        white-space: pre;
        line-height: 140%;
        display: block;
        width: 100%;
        text-align: ${(p) => p.textParams.horizontalAlign};
        flex-shrink: 0;
        margin: 0;
        padding: 0;
    }
`;

const Container = styled.div`
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;
    position: ${(p) => (!p.interactive ? p.position : 'relative')};
    width: ${(p) => (!p.interactive ? p.size.width + 'px' : '100%')};
    height: ${(p) => (!p.interactive ? p.size.height + 'px' : '100%')};
    left: ${(p) => (!p.interactive && p.position === 'absolute' ? `${p.size.left}px` : '0')};
    top: ${(p) => (!p.interactive && p.position === 'absolute' ? `${p.size.top}px` : '0')};
    border-style: dashed;
    border-width: ${(p) => (p.border.width ? p.border.width + 'px' : '2px')};
    border-color: ${(p) => {
        if (p.dimBlock && p.interactive) {
            return '#555';
        } else if (p.border.color) {
            return p.border.color;
        } else {
            return '#ddd';
        }
    }};
    border: ${(p) => p.isPreview && 'none'};
    border-radius: ${(p) => (p.block && p.block.style ? p.block.style.borderRadius + 'px' : 'none')};
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border;
    margin-bottom: ${(p) => (p.marginBottom ? p.marginBottom + 'px' : '0')};
    flex-shrink: 0;
    z-index: ${(p) => (!p.isPreview ? p.zIndex : '10')};

    background-color: ${(p) => (p.block ? p.block.backgroundColor : 'none')};
    background-image: ${(p) => (p.block && p.block.media && p.block.media[0] ? `url('${p.pathToFiles}${p.block.media[0].url}')` : null)};
    background-size: ${(p) =>
        p.block && p.block.media && p.block.media[0] && p.block.media[0].BGSize ? `${p.block.media[0].BGSize}` : 'cover'};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: 0.3s ease-in-out;
    pointer-events: ${(p) => (p.interactive ? 'auto' : 'none')};
    :hover {
        box-shadow: ${(p) => !p.isPreview && (p.interactive ? '0 5px 5px rgba(0, 0, 0, 0.2)' : 'none')};
        transform: ${(p) => !p.isPreview && (p.interactive ? 'scale(1.02)' : 'none')};
    }
    ::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(100, 100, 100, 0.9);
        display: ${(p) => (p.dimBlock && p.interactive && !p.isPreview ? 'block' : 'none')};
    }
    span {
        font-size: 12px;
    }
`;

const ContainerLink = Container.withComponent('a');
