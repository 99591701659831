import React from 'react';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components/macro';
import Context from '../context';

const MySwitch = React.memo((props) => {
    const { state, dispatch } = React.useContext(Context);
    const { palette } = state;

    return <StyledSwitch palette={palette} {...props} />;
});

export default MySwitch;

const StyledSwitch = styled(Switch)`
    .Mui-checked {
        .MuiSwitch-thumb {
            background-color: ${(p) => p.palette.themePrimary};
        }
    }
    .MuiSwitch-track {
        background-color: ${(p) => (p.checked ? p.palette.themePrimary + '!important' : p.palette.neutralPrimary)};
    }
    .MuiTouchRipple-root {
        color: ${(p) => (p.checked ? p.palette.themePrimary + '!important' : 'transparent')};
    }
`;
