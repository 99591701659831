import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Button from './button';

import { useTranslation } from 'react-i18next';
import MediaPicker from './conrtol_panel/components/media_picker';

import Context from '../context';

const styles = theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper
    }
});

const ImgPickerModal = React.memo(() => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { showImgPickerModal, windowDimensions, pathToFiles } = state;

    const closeModal = e => {
        e.stopPropagation();
        dispatch({
            type: 'toggleImgPickerModal',
            payload: { show: false }
        });
    };

    const onContainerClick = e => {
        e.stopPropagation();
    };

    const onImgContainerClick = item  => {
        // e.stopPropagation();
        dispatch({
            type: 'handleImgPickerModalClick',
            payload: item
        })
    };

    return (
        <Overlay show={showImgPickerModal.show} onClick={closeModal}>
            <Container width={1200} height={800} onClick={onContainerClick} windowDimensions={windowDimensions}>
                <MediaPicker
                    colCount={12}
                    showCurrentImg={false}
                    currentImg={`${showImgPickerModal.options.autopost_img}`}
                    onImgContainerClick={onImgContainerClick}
                    isContainChecked={undefined}
                    onContainCheckboxChange={() => {}}
                    onDeleteImageButtonClick={() => {}}
                />
            </Container>
        </Overlay>
    );
});

export default ImgPickerModal;

const Footer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 10px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
`;
const Title = styled.h2`
    display: flex;
    width: 100%;
    color: #4169e1;
    font-family: 'Open Sans';
    justify-content: center;
`;

const TabContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0.5%;
    width: 100%;
    overflow: auto;
`;

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display: ${p => (p.show ? 'block' : 'none')};
`;
const Container = styled.div`
    padding: 5px;
    position: absolute;
    left: 50%;
    top: 50px;
    margin-left: ${p => -p.width / 2 + 'px'};
    /* margin-top: ${p => -p.height / 2 + 'px'}; */
    width: ${p => p.width + 'px'};
    max-height: ${p => p.windowDimensions.height - 100 + 'px'};
    /* height: ${p => p.height + 'px'}; */
    background-color: whitesmoke;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
`;
