import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components/macro';

const LevelTitle = React.memo(({ palette, children }) => {
    return <Title palette={palette}>{children}</Title>;
});

export default LevelTitle;

const Title = styled.h3`
    display: flex;
    width: 100%;
    color: ${p=>p.palette.themePrimary};
    font-family: 'Open Sans';
    justify-content: center;
`;
