import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const PasswordOptions = React.memo(({ auth_title, options, level, ...props }) => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { pageId } = state;

    const onPassChange = e => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, password: e.target.value } }
        });
    };

    return (
        <InputContainer>
            <TextField
                label={t('Пароль')}
                multiline
                fullWidth
                value={options.password || '1234'}
                onChange={onPassChange}
                margin="dense"
            />
        </InputContainer>
    );
});

export default PasswordOptions;

const InputContainer = React.memo(styled.div`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 0 10px 5px 20px;
    padding-right: 10px;
    padding-left: 10px;
    span {
        display: flex;
        align-items: center;
    }
`);
