import React, { useContext, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components/macro';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useTranslation } from 'react-i18next';
import Input from '@material-ui/core/Input';
import Button from './button';

import { API } from '../tools';
import Context from '../context';

const ImageUploadModal = React.memo(() => {
    const { state, dispatch } = useContext(Context);
    const { showImageUploadModal, cursorCoords, token, pathToFiles, palette } = state;
    const [file, setFile] = useState(null);
    const [tags, setTags] = useState('');
    const [title, setTitle] = useState('');
    const { t } = useTranslation();

    const closeModal = async () => {
        dispatch({
            type: 'toggleUploadModal',
            payload: { show: false },
        });
    };

    const applyModal = async () => {
        if (file === null) {
            return;
        }
        try {
            const url = `${pathToFiles}/content/upload/`;
            const formData = new FormData();
            formData.append('access_token', token);
            formData.append('file', file);
            formData.append('title', imgTitle);
            formData.append('tags', tags);
            formData.append('API_HOST', '');
            const response = await axios.post(url, formData);
            API(`${pathToFiles}/content/getfiles/?access_token=${token}`, undefined, 'setMedia', dispatch);
            setFile(null);
        } catch (error) {
            console.warn(error);
        }
        dispatch({
            type: 'toggleUploadModal',
            payload: { show: false },
        });
    };

    const onChange = (e) => {
        const file = [...e.target.files][0];
        if (
            ((file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png') && file.size > 3 * 1024 * 1024) ||
            (file.type === 'image/gif' && file.size > 8 * 1024 * 1024)
        ) {
            setFile(null);
            alert(t('Превышен максимальный размер изображения'));
        } else {
            setFile(file);
        }
    };

    const onChangeTags = (e) => {
        setTags(e.target.value);
    };

    const onChangeTitle = (e) => {
        setTitle(e.target.value);
    };

    const onContainerClick = (e) => {
        e.stopPropagation();
    };

    const inputStyle = {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
        padding: '10px 30px',
    };

    const imgTitle = React.useMemo(() => {
        if (title) {
            return title;
        } else if (file) {
            return file.name;
        } else return '';
    }, [title, file]);

    return (
        <Overlay show={showImageUploadModal} onClick={closeModal}>
            <Container cursorCoords={cursorCoords} onClick={onContainerClick}>
                <Header></Header>
                <Main>
                    <input
                        accept="image/jpeg,image/jpg,image/png,image/gif"
                        onChange={onChange}
                        style={{ display: 'none' }}
                        id="raised-button-file"
                        type="file"
                    />
                    <label htmlFor="raised-button-file">
                        <ChooseFile palette={palette}>{t('Выберите файл изображения').toUpperCase()}</ChooseFile>
                        <FileName>{file && file.name}</FileName>
                    </label>
                    <StInput
                        disableUnderline
                        style={inputStyle}
                        placeholder={t('Название')}
                        value={imgTitle}
                        onChange={onChangeTitle}
                        margin="dense"
                        fullWidth
                    />
                    <StInput
                        disableUnderline
                        style={inputStyle}
                        placeholder={t('Тэги, разделенные запятыми')}
                        value={tags}
                        onChange={onChangeTags}
                        margin="dense"
                        fullWidth
                    />
                </Main>

                <Footer>
                    <Button variant="outlined" color="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button margin={'0 0 0 10px'} variant="contained" color="primary" onClick={applyModal}>
                        <StCloudUploadIcon />
                        OK
                    </Button>
                </Footer>
            </Container>
        </Overlay>
    );
});

export default ImageUploadModal;

const StCloudUploadIcon = styled(CloudUploadIcon)`
    margin-right: 10px;
`;

const StInput = styled(Input)`
    margin-top: 10px !important;
`;

const ChooseFile = styled.span`
    height: 36px;
    width: auto;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: ${(p) => `1px solid ${p.palette.themePrimary}`};
    color: ${(p) => p.palette.themePrimary};
    border-radius: 4px;
    padding: 5px 16px;
    :hover {
        background-color: ${(p) => p.palette.neutralLight};
    }
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;
const FileName = styled.span`
    margin-left: 10px;
`;

const Header = styled.div``;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    label {
        cursor: pointer;
    }
`;
const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display: ${(p) => (p.show ? 'block' : 'none')};
`;
const Container = styled.div`
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -240px;
    margin-left: -320px;
    width: 640px;
    height: 480px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-rows: 1fr 5fr 1fr;
`;
