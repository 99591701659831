import axios from 'axios';

export const generateInitialAuthControl = () => {
    const result = {
        layersCount: 1,
        globalOptions: [
            { name: 'sessionPeriod', value: 30 },
            { name: 'nextConnectionPeriod', value: 0 },
            { name: 'authPeriod', value: 1440 },
            { name: 'traficVolume', value: 500 },
            { name: 'redirectUrl', value: 'http://google.com' },
        ],
    };
    for (let i = 0; i < 3; i++) {
        result[i] = [
            {
                auth_title: 'sms',
                title: 'SMS авторизация',
                radio: true,
                checked: false,
                disabled: false,
                options: {
                    expiration_sms_period: 15552000,
                },
            },
            {
                auth_title: 'call',
                title: 'Авторизация по звонку',
                radio: true,
                checked: false,
                disabled: false,
                options: {
                    expiration_call_period: 15552000,
                },
            },
            {
                auth_title: 'button',
                title: `Кнопка 'войти в интернет'`,
                radio: false,
                checked: false,
                disabled: false,
                options: {},
            },
            {
                auth_title: 'vkontakte',
                title: 'Вход через Vkontakte',
                radio: false,
                checked: false,
                disabled: false,
                options: {
                    autopost: false,
                    repost: false,
                    autopost_details: '',
                    autopost_message: '',
                },
            },
            {
                auth_title: 'facebook',
                title: 'Вход через Facebook',
                radio: false,
                checked: false,
                disabled: false,
                options: {
                    autopost: false,
                    autopost_description: '',
                    autopost_title: '',
                    autopost_url: '',
                    autopost_img: '',
                },
            },
            {
                auth_title: 'odnoklassniki',
                title: 'Вход через Odnoklassniki',
                radio: false,
                checked: false,
                disabled: false,
                options: {},
            },
            {
                auth_title: 'password',
                title: 'Вход с паролем',
                radio: false,
                checked: false,
                disabled: false,
                options: {
                    password: '1234',
                },
            },
            {
                auth_title: 'external',
                title: 'Сторонняя авторизация',
                radio: true,
                checked: false,
                disabled: false,
                options: {
                    confirmation_url: '',
                    external_url: '',
                },
            },
        ];
    }
    return result;
};

export const parseQuery = queryString => {
    const query = {};
    const pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
};

export const blockSize = (parentSize, block, borderWidth, style = {}) => {
    const width =
        (parentSize.width - 10) * block.rel_width - Number(style.paddingLeft || 0) - Number(style.paddingRight || 0) - 2 * borderWidth;
    const height = width * block.ratio - 2 * borderWidth;
    const ratio = `1 x ${block.ratio}`;
    const marginLeft = Number(style.paddingLeft || 0);
    const marginRight = Number(style.paddingRight || 0);
    const marginTop = Number(style.paddingTop || 0);
    const marginBottom = Number(style.paddingBottom || 0);
    return {
        ratio,
        width,
        height,
        marginLeft,
        marginRight,
        marginTop,
        marginBottom
    };
};

export const subBlockSize = (parentSize, block, borderWidth, style = {}) => {
    const width = parentSize.width * block.width - Number(style.paddingLeft || 0) - Number(style.paddingRight || 0) - 2 * borderWidth;
    const height = parentSize.height * block.height - 2 * borderWidth;
    const left = parentSize.width * block.left + Number(style.paddingLeft || 0);
    const top = parentSize.height * block.top + Number(style.paddingTop || 0);
    const ratio = `1 x ${(height / width).toFixed(1)}`;
    return {
        ratio,
        width,
        height,
        left,
        top
    };
};

export const isIndexesEqual = (obj1, obj2) => {
    if (!obj1 || !obj2) return false;
    if (obj1.block === obj2.block && obj1.subBlock === obj2.subBlock) {
        return true;
    } else {
        return false;
    }
};

export const calculateSizes = (position, prototype, parentPrototype, constructorSize, style = {}, isWrapper) => {
    const parentWidth = constructorSize.width * parentPrototype.rel_width;
    const parentHeight = constructorSize.width * parentPrototype.rel_width * parentPrototype.ratio;
    const paddingTop = `${Number(style.paddingTop || 0)}px`;
    const paddingBottom = `${Number(style.paddingBottom || 0)}px`;
    const paddingLeft = `${Number(style.paddingLeft || 0)}px`;
    const paddingRight = `${Number(style.paddingRight || 0)}px`;
    const borderRadius = `${Number(style.borderRadius || 0)}px`;

    if (position === 'absolute') {
        const left = `${parentWidth * prototype.left}px`;
        const top = `${parentHeight * prototype.top}px`;
        const width = `${parentWidth * prototype.width}px`;
        const height = `${parentHeight * prototype.height}px`;
        // const height = `${parentHeight * prototype.height + Number(style.paddingTop || 0)}px`;
        return {
            left,
            top,
            width,
            height,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            borderRadius
        };
    } else {
        const width = `${parentWidth}px`;
        const height = `${parentHeight}px`;
        // const height = `${parentHeight + Number(style.paddingTop || 0)}px`;
        return {
            left: 0,
            top: 0,
            width,
            height,
            paddingTop,
            paddingBottom,
            paddingLeft,
            paddingRight,
            borderRadius
        };
    }
};

export const arraymove = (arr, fromIndex, toIndex) => {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
};

export const API = async (url, token, actionName, dispatch) => {
    try {
        const headers = token
            ? {
                  'x-token': token
              }
            : {};
        const response = await axios.get(url, { headers });
        // console.log(response);
        dispatch({
            type: actionName,
            payload: await response.data
        });
    } catch (error) {
        console.log('>>>>>>', error);
    }
};

export const onClickSave = async (pathToFiles, title, dispatch, token, constructor, pageId, showAuthParams) => {
    dispatch({
        type: 'showSpinner',
        payload: true
    });
    await dispatch({
        type: 'onSaveAuthModalClick'
    });
    try {
        const c = { ...constructor };
        if (!showAuthParams) {
            c.authParams = null;
        }
        const url = `${pathToFiles}/content/save_page/`;
        const json = JSON.stringify(c, null, 4);
        const formData = new FormData();
        formData.append('access_token', token);
        formData.append('page_content', json);
        formData.append('page_id', pageId);
        formData.append('page_title', title);
        formData.append('API_HOST', '');

        const response = await axios.post(url, formData);
        dispatch({
            type: 'showSpinner',
            payload: false
        });
        API(`${pathToFiles}/content/get_pages/?access_token=${token}`, undefined, 'setPages', dispatch);
    } catch (error) {
        console.warn(error);
        dispatch({
            type: 'showSpinner',
            payload: false
        });
    }
};
