import React, { useContext } from 'react';
import axios from 'axios';
import Button from '../../button';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components/macro';
import { API } from '../../../tools';
import Context from '../../../context';
import { useTranslation } from 'react-i18next';
import ContainCheckbox from './contain_checkbox';
import deleteIcon from './img/delete_icon.svg';

const MediaPicker = React.memo(
    ({ currentImg, colCount = 3, showCurrentImg = true, isContainChecked, onContainCheckboxChange, ...props }) => {
        const { state, dispatch } = useContext(Context);
        const { pathToFiles, media } = state;
        const { t, i18n } = useTranslation();
        const onImgContainerClick = (item) => (e) => {
            props.onImgContainerClick(item);
        };

        const onUploadClick = () => {
            dispatch({
                type: 'toggleUploadModal',
                payload: { show: true },
            });
        };

        const deleteRequest = async (url) => {
            try {
                const headers = {
                    'Content-type': 'application/json',
                };
                await axios.get(url, { headers });
                props.onDeleteImageButtonClick();
                await API(`${pathToFiles}/content/getfiles/?access_token=${state.token}`, undefined, 'setMedia', dispatch);
            } catch (error) {
                console.log('>>>>>>', error);
            }
        };

        const onDeleteImageFromMediaButtonClick = (img) => (e) => {
            e.stopPropagation();
            deleteRequest(`${pathToFiles}/content/deletefile/?access_token=${state.token}&media_id=${img.id}&API_HOST=&_=1581670058510`);
        };

        return media.user_items ? (
            <Container>
                {showCurrentImg && (
                    <CurrentImgContainer>
                        <CloseButton onClick={props.onDeleteImageButtonClick} />
                        <CurrentImg src={currentImg} alt={t('Изображение не задано')} />
                        {isContainChecked !== undefined && (
                            <ContainCheckbox checked={isContainChecked} onChange={onContainCheckboxChange} />
                        )}
                    </CurrentImgContainer>
                )}
                <ButtonWrapper>
                    <Button variant="outlined" color="primary" onClick={onUploadClick}>
                        {t('Изображение с диска')}
                    </Button>
                </ButtonWrapper>
                <Ul colCount={colCount}>
                    {media.user_items.map((item, i) => {
                        return (
                            <ImgContainer key={`${item.file_url}-${i}`} onClick={onImgContainerClick(item)}>
                                <Tooltip title={t('Удалить из медиатеки')}>
                                    <DeleteButton onClick={onDeleteImageFromMediaButtonClick(item)} />
                                </Tooltip>
                                <Img src={`${pathToFiles}${item.file_url}`} />
                            </ImgContainer>
                        );
                    })}
                </Ul>
            </Container>
        ) : null;
    }
);

export default MediaPicker;

const Img = styled.img`
    width: 100%;
    object-fit: cover;
`;

const CurrentImg = styled.img`
    width: 100%;
    object-fit: contain;
`;

const Container = styled.div``;
const ButtonWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: 10px;
`;

const CloseButton = styled.div`
    cursor: pointer;
    border: 2px solid red;
    border-radius: 50%;
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
    right: -10px;
    top: -5px;
    background: url(${deleteIcon}) no-repeat center;
    background-color: #fff;
    background-size: contain;
    opacity: 0.4;
    transition: 0.3s ease-in-out;
    :hover {
        opacity: 1;
    }
    :active {
        transform: scale(0.9);
    }
`;

const DeleteButton = styled.div`
    cursor: pointer;
    border: 2px solid red;
    border-radius: 50%;
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    right: 3px;
    top: 3px;
    background: url(${deleteIcon}) no-repeat center;
    background-color: #fff;
    background-size: contain;
    opacity: 0.5;
    transition: 0.3s ease-in-out;
    :hover {
        opacity: 1;
    }
    :active {
        transform: scale(0.9);
    }
`;

const CurrentImgContainer = styled.div`
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
`;

const ImgContainer = styled.div`
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    div {
        display: none;
    }
    :hover {
        transform: scale(1.03);
        div {
            display: block;
        }
    }
`;

const Ul = styled.ul`
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(${(p) => p.colCount}, 1fr);
    grid-gap: 10px;
    overflow: scroll;
    li {
        justify-self: center;
        width: 75px;
        height: 112px;
        display: flex;
        flex-shrink: 0;
    }
`;
