import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';

const ExternalOptions = React.memo(({ auth_title, options, level, ...props }) => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { pageId } = state;

    const onTextChange = name => e => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, [`${name}_url`]: e.target.value } }
        });
    };

    return (
        <InputContainer>
            <TextField
                label={t('Внешний URL')}
                multiline
                fullWidth
                value={options.external_url || ''}
                onChange={onTextChange('external')}
                margin="dense"
            />
            <TextField
                label={t('URL запроса проверки')}
                multiline
                fullWidth
                value={options.confirmation_url || ''}
                onChange={onTextChange('confirmation')}
                margin="dense"
            />
        </InputContainer>
    );
});

export default ExternalOptions;

const InputContainer = React.memo(styled.div`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 0 10px 5px 20px;
    padding-right: 10px;
    padding-left: 10px;
`);
