import { arraymove } from './tools';

const openAccordionSection = (accordion, number, mode) => {
    // const { accordion } = state.controlPanel;
    Object.keys(accordion).forEach((item) => {
        if (Number(item) !== number) {
            accordion[item] = false;
        } else {
            accordion[item] = mode === 'toggle' ? !accordion[item] : true;
        }
    });
    return accordion;
};

const setQueryParams = (state, action) => {
    const { xToken } = action.payload;
    const pageId = action?.payload?.pageId ? Number(action.payload.pageId) : state?.pages[0]?.id ? state.pages[0].id : null;
    const mainLocation = action.payload.mainLocation ? action.payload.mainLocation : state.mainLocation;
    const lang = action.payload.lang ? action.payload.lang : state.lang;

    return { ...state, token: xToken, pageId, mainLocation, lang };
};

const setLoginResponse = (state, action) => {
    const loginResponse = action.payload;
    return { ...state, loginResponse };
};

const setPages = (state, action) => {
    const pages = action.payload.pages.filter((item) => !item.is_default);
    let pageTitle = state.pageTitle
    let pageUUID = state.pageUUID
    let pageId = state.pageId

    try {
        pageTitle = pages ? pages.filter((page) => page.id === state.pageId)[0].title : '';
        pageUUID = pages ? pages.filter((page) => page.id === state.pageId)[0].uuid : '';
    } catch (error) {}

    if (!state.pageId && pages) {
        pageTitle = pages[0].title || '';
        pageUUID = pages[0].uuid || '';
        pageId = pages[0].id || '';
    }

    const AuthOptionsModalData = { ...state.AuthOptionsModalData };
    const { changedPages } = state;
    pages.forEach((item) => {
        AuthOptionsModalData[item.id] = JSON.parse(item.data).authParams || null;
    });

    const AuthControlData = { ...state.AuthControlData };
    pages.forEach((item) => {
        changedPages[item.id] = false;
        AuthControlData[item.id] = { ...state.AuthControl };
        const authParams = JSON.parse(item.data).authParams || undefined;
        if (authParams) {
            AuthControlData[item.id].layersCount = authParams.layersCount || 1;

            AuthControlData[item.id].globalOptions = [
                ...AuthControlData[item.id].globalOptions.map((option) => {
                    if (option.value === authParams[option.name]) {
                        return option;
                    } else {
                        return {
                            name: option.name,
                            value:
                                authParams[option.name] !== undefined
                                    ? authParams[option.name]
                                    : state.AuthControl.globalOptions.filter((item) => item.name === option.name)[0].value,
                        };
                    }
                }),
            ];

            authParams.layers.forEach((layer, i) => {
                layer.forEach((obj) => {
                    const radio = AuthControlData[item.id][i].filter((data) => data.auth_title === obj.auth_title)[0]?.radio;
                    AuthControlData[item.id][i] = [
                        ...AuthControlData[item.id][i].map((data) => {
                            if (data.auth_title !== obj.auth_title) {
                                return { ...data, disabled: radio ? true : data.radio ? true : data.disabled };
                            } else {
                                Object.keys(AuthControlData[item.id]).forEach((key) => {
                                    if (Number(key) !== i && key !== 'layersCount' && key !== 'globalOptions') {
                                        AuthControlData[item.id][key] = [
                                            ...AuthControlData[item.id][key].map((item) => {
                                                if (item.auth_title !== obj.auth_title) {
                                                    return item;
                                                } else {
                                                    return { ...item, disabled: true };
                                                }
                                            }),
                                        ];
                                    }
                                });

                                const options = {};
                                Object.keys(obj).forEach((field) => {
                                    if (field !== 'auth_title' && field !== 'title') {
                                        options[field] = obj[field];
                                    }
                                });

                                return {
                                    ...data,
                                    options: { ...options },
                                    checked: true,
                                    // disabled: radio ? true : data.radio ? true : data.disabled
                                };
                            }
                        }),
                    ];
                });
            });
        }
    });

    return { ...state, pages, pageTitle, pageUUID, pageId, AuthOptionsModalData, AuthControlData };
};

const getPages = (state, action) => {
    const pages = action.payload.pages.filter((item) => !item.is_default);
    return { ...state, pages };
};

const setBlockTypes = (state, action) => {
    const blockTypes = action.payload.block_types;
    return { ...state, blockTypes };
};

const setMedia = (state, action) => {
    const media = action.payload;
    return { ...state, media };
};

const toggleCollapse = (state, action) => {
    const { accordion } = state.controlPanel;
    return {
        ...state,
        controlPanel: {
            ...state.controlPanel,
            accordion: openAccordionSection(accordion, action.payload, 'toggle'),
        },
    };
};

const toggleBlockParamsCollapse = (state, action) => {
    const id = action.payload;
    const { accordion } = state.blockParams;
    return {
        ...state,
        blockParams: {
            ...state.blockParams,
            accordion: openAccordionSection(accordion, id, 'toggle'),
        },
    };
};

const togglePageParamsCollapse = (state, action) => {
    const id = action.payload;
    const { accordion } = state.pageParams;
    return {
        ...state,
        pageParams: {
            ...state.pageParams,
            accordion: openAccordionSection(accordion, id, 'toggle'),
        },
    };
};

const handleChangeBackColor = (state, action) => {
    const { constructor, pageId, changedPages } = state;
    changedPages[pageId] = true;
    const { r, g, b, a } = action.payload;
    const backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    constructor.backgroundColor = backgroundColor;
    // constructor.media[0].url = undefined;
    return { ...state, constructor, changedPages };
};

const setConstructor = (state, action) => {
    const { json } = action.payload;
    const showAuthParams = json && json.authParams && json.authParams !== null ? true : false;
    return { ...state, constructor: json, showAuthParams };
};

const changeBgImage = (state, action) => {
    const { constructor, pageId, changedPages } = state;
    changedPages[pageId] = true;
    constructor.media = [{ url: action.payload.file_url }];

    return { ...state, constructor, changedPages };
};

const onAddBlockClick = (state, action) => {
    const { constructor, blockTypes, pageId, changedPages } = state;
    changedPages[pageId] = true;
    const index = action.payload;
    if (!constructor.blocks) {
        constructor.blocks = [];
    }
    constructor.blocks.push({
        prototype: blockTypes[index],
        params: {},
        subBlocks: blockTypes[index].details.subblocks
            ? blockTypes[index].details.subblocks.map((item) => {
                  return {
                      prototype: item,
                      params: {},
                  };
              })
            : [],
    });

    return { ...state, constructor, changedPages };
};

const onConstructorBlockClick = (state, action) => {
    const { accordion } = state.controlPanel;
    return {
        ...state,
        selectedBlock: action.payload,
        controlPanel: { ...state.controlPanel, accordion: openAccordionSection(accordion, 4, 'open') },
    };
};

const onMainBGClick = (state, action) => {
    const { accordion } = state.controlPanel;
    return {
        ...state,
        selectedBlock: undefined,
        controlPanel: { ...state.controlPanel, accordion: openAccordionSection(accordion, 2, 'open') },
    };
};

const handleChangeBlockBGColor = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { bgColor, selectedBlock } = action.payload;
    const { r, g, b, a } = bgColor;
    const backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`;
    if (selectedBlock.subBlock === undefined) {
        constructor.blocks[selectedBlock.block].backgroundColor = backgroundColor;
    } else {
        constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock].backgroundColor = backgroundColor;
    }

    return {
        ...state,
        constructor,
        changedPages,
    };
};

const changeBlockBgImage = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { item, selectedBlock } = action.payload;
    if (selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        block.media = block.media ? [{ ...block.media[0], url: item.file_url }] : [{ url: item.file_url }];
    } else {
        const subBlock = constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        subBlock.media = subBlock.media ? [{ ...subBlock.media[0], url: item.file_url }] : [{ url: item.file_url }];
    }
    return { ...state, constructor, changedPages };
};

const onDeleteImageButtonClick = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    if (!action.payload) {
        constructor.media = undefined;
        return { ...state, constructor, changedPages };
    }

    const { selectedBlock } = action.payload;
    if (selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        block.media = undefined;
    } else {
        const subBlock = constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        subBlock.media = undefined;
    }
    return { ...state, constructor, changedPages };
};

const onBlockContainCheckboxChange = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { checked, selectedBlock } = action.payload;
    if (selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        if (!block.media) return state;
        block.media[0].BGSize = checked ? 'contain' : 'cover';
    } else {
        const subBlock = constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        if (!subBlock.media) return state;
        subBlock.media[0].BGSize = checked ? 'contain' : 'cover';
    }

    return { ...state, constructor, changedPages };
};

const onFormatChange = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { formatType, value, selectedBlock } = action.payload;
    if (selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        if (!block.textParams) {
            block.textParams = {};
        }
        block.textParams[formatType] = value;
    } else {
        const subBlock = constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        if (!subBlock.textParams) {
            subBlock.textParams = {};
        }
        subBlock.textParams[formatType] = value;
    }

    return { ...state, constructor, changedPages };
};

const fillDefaultTextParams = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { textParams, selectedBlock } = action.payload;
    console.log(textParams, selectedBlock);

    if (constructor?.blocks[selectedBlock.block] && selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        block.textParams = { ...textParams };
    } else {
        const subBlock = constructor?.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        if (subBlock) {
            subBlock.textParams = { ...textParams };
        }
    }

    return { ...state, constructor, changedPages };
};

const fillDefaultStyle = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { style, selectedBlock } = action.payload;
    if (selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        block.style = { ...style };
    } else {
        const subBlock = constructor?.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        if (subBlock) {
            subBlock.style = { ...style };
        }
    }

    return { ...state, constructor, changedPages };
};

const onBorderInputChange = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { name, value, selectedBlock } = action.payload;
    if (selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        console.log(block);
        block.style = { ...block.style, [name]: value };
    } else {
        const subBlock = constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        subBlock.style = { ...subBlock.style, [name]: value };
    }

    return { ...state, constructor, changedPages };
};

const onWebLinkChange = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { url, selectedBlock } = action.payload;
    if (selectedBlock.subBlock === undefined) {
        const block = constructor.blocks[selectedBlock.block];
        block.params = { ...block.params, url };
    } else {
        const subBlock = constructor.blocks[selectedBlock.block].subBlocks[selectedBlock.subBlock];
        subBlock.params = { ...subBlock.params, url };
    }

    return { ...state, constructor, changedPages };
};

const onPageContainCheckboxChange = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { checked } = action.payload;
    if (!constructor.media) return state;
    constructor.media[0].BGSize = checked ? 'contain' : 'cover';
    return { ...state, constructor, changedPages };
};

const onDeleteBlockClick = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const index = action.payload.selectedBlock.block;
    constructor.blocks.splice(index, 1);
    return { ...state, selectedBlock: undefined, constructor, changedPages };
};

const onBlockMoveClick = (state, action) => {
    const { constructor, changedPages, pageId } = state;
    changedPages[pageId] = true;
    const index = action.payload.selectedBlock.block;
    const { direction } = action.payload;
    switch (direction) {
        case 'up':
            arraymove(constructor.blocks, index, index - 1);
            break;
        case 'down':
            arraymove(constructor.blocks, index, index + 1);
            break;
        default:
            break;
    }
    return { ...state, selectedBlock: undefined, constructor, changedPages };
};

const setWindowDimensions = (state, action) => {
    const windowDimensions = action.payload;
    return { ...state, windowDimensions };
};

const setCurrentPage = (state, action) => {
    const { changedPages } = state;
    const { pageId, pageTitle, pageUUID, currentPageId } = action.payload;
    changedPages[currentPageId] = false;
    return { ...state, pageId, pageTitle, pageUUID, changedPages, selectedBlock: undefined };
};

const toggleCreateNewModal = (state, action) => {
    const { show, cursorCoords } = action.payload;
    return { ...state, showCreateNewModal: show, cursorCoords };
};

const changePageTitle = (state, action) => {
    const { newTitle } = action.payload;
    return { ...state, pageTitle: newTitle };
};

const toggleShowAuthParams = (state, action) => {
    const { changedPages, pageId } = state;
    changedPages[pageId] = true;
    if (!state.showAuthParams) {
        const { authParams, authBackgroundColor, authButtonBackgroundColor, authButtonText, authDisplayMode } = state.constructor;
        const params = {
            authBackgroundColor: authBackgroundColor ? authBackgroundColor : 'rgba(51, 71, 80, 0.9)',
            authButtonBackgroundColor: authButtonBackgroundColor ? authButtonBackgroundColor : 'rgba(51, 71, 80, 0.9)',
            authButtonText: authButtonText ? authButtonText : 'ВОЙТИ В ИНТЕРНЕТ',
            authDisplayMode: authDisplayMode ? authDisplayMode : false,
            authParams: authParams !== undefined && authParams !== null ? authParams : state.defaultAuthParams.authParams,
        };
        return {
            ...state,
            changedPages,
            showAuthParams: !state.showAuthParams,
            constructor: { ...state.constructor, ...params },
        };
    } else {
        return { ...state, changedPages, showAuthParams: !state.showAuthParams };
    }
};

const fillDefaultAuthParams = (state, action) => {
    const { defaultAuthParams } = state;
    return { ...state, constructor: { ...state.constructor, ...defaultAuthParams } };
};

const onChangeAuthButtonBackgroundColor = (state, action) => {
    const { changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { authButtonBackgroundColor } = action.payload;
    return { ...state, changedPages, constructor: { ...state.constructor, authButtonBackgroundColor } };
};

const onChangeAuthBackgroundColor = (state, action) => {
    const { changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { authBackgroundColor } = action.payload;
    return { ...state, changedPages, constructor: { ...state.constructor, authBackgroundColor } };
};

const onInternetButtonTextChange = (state, action) => {
    const { changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { authButtonText } = action.payload;
    return { ...state, changedPages, constructor: { ...state.constructor, authButtonText } };
};

const onToggleAuthLandingShow = (state, action) => {
    const { changedPages, pageId } = state;
    changedPages[pageId] = true;
    const { authDisplayMode } = { ...state.constructor };
    return { ...state, changedPages, constructor: { ...state.constructor, authDisplayMode: !authDisplayMode } };
};

const toggleAuthOptionsModal = (state, action) => {
    const showAuthOptionsModal = action.payload.show;
    const tempAuthOptions = showAuthOptionsModal ? { ...state.AuthControlData[state.pageId] } : {};
    if (!showAuthOptionsModal) {
        return {
            ...state,
            showAuthOptionsModal,
            AuthControlData: { ...state.AuthControlData, [state.pageId]: state.tempAuthOptions },
        };
    }
    return { ...state, showAuthOptionsModal, tempAuthOptions };
};

const toggleImgPickerModal = (state, action) => {
    const { show, auth_title, level } = action.payload;
    const options = action.payload.options ? { ...action.payload.options } : { ...state.showImgPickerModal.options };
    return { ...state, showImgPickerModal: { ...state.showImgPickerModal, show, options, auth_title, level } };
};

const handleImgPickerModalClick = (state, action) => {
    const AuthControlData = { ...state.AuthControlData };
    const { pageId } = state;
    const { level, auth_title } = state.showImgPickerModal;
    AuthControlData[pageId][level - 1] = [
        ...AuthControlData[pageId][level - 1].map((item) => {
            if (item.auth_title !== auth_title) {
                return item;
            } else {
                return { ...item, options: { ...item.options, autopost_img: action.payload.file_url } };
            }
        }),
    ];
    return { ...state, AuthControlData };
};

const clearImgPickerModal = (state, action) => {
    const AuthControlData = { ...state.AuthControlData };
    const { pageId } = state;
    const { level, auth_title } = action.payload;
    AuthControlData[pageId][level - 1] = [
        ...AuthControlData[pageId][level - 1].map((item) => {
            if (item.auth_title !== auth_title) {
                return item;
            } else {
                return { ...item, options: { ...item.options, autopost_img: '' } };
            }
        }),
    ];
    return { ...state, AuthControlData };
};

const handleAuthOptionsChange = (state, action) => {
    const AuthControlData = { ...state.AuthControlData };
    const { pageId, level, auth_title, options } = action.payload;
    AuthControlData[pageId][level - 1] = [
        ...AuthControlData[pageId][level - 1].map((item) => {
            if (item.auth_title !== auth_title) {
                return item;
            } else {
                return { ...item, options };
            }
        }),
    ];
    return { ...state, AuthControlData };
};

const handleGlobalAuthOptionsChange = (state, action) => {
    const AuthControlData = { ...state.AuthControlData };
    const { pageId } = state;
    const { name, value, type } = action.payload;
    AuthControlData[pageId].globalOptions = [
        ...AuthControlData[pageId].globalOptions.map((item) => {
            if (item.name !== name) {
                return item;
            } else {
                return { name, value: type === 'number' ? Number(value) : value };
            }
        }),
    ];
    return { ...state, AuthControlData };
};

const onChangeLayersCount = (state, action) => {
    const AuthControlData = { ...state.AuthControlData };
    const { pageId } = state;
    const { count } = action.payload;
    AuthControlData[pageId].layersCount = count;
    return { ...state, AuthControlData };
};

const showSpinner = (state, action) => {
    return { ...state, showSpinner: action.payload };
};

const onSaveAuthModalClick = (state, action) => {
    const AuthControlData = { ...state.AuthControlData };
    const { pageId, constructor } = state;
    let layers = [];
    for (let i = 0; i < AuthControlData[pageId]?.layersCount; i++) {
        layers[i] = [];
        AuthControlData[pageId][i].forEach((item) => {
            if (item.checked) {
                layers[i].push({ auth_title: item.auth_title, title: item.title, ...item.options });
            }
        });
    }
    layers = [
        ...layers.filter(
            (item, i, arr) => item.length !== 0 || (arr[i + 1] && arr[i + 1].length !== 0) || (arr[i + 2] && arr[i + 2].length !== 0)
        ),
    ];
    if (constructor?.authParams) {
        constructor.authParams.layers = layers;
        AuthControlData[pageId].globalOptions.forEach((item) => {
            constructor.authParams[item.name] = item.value;
        });
        constructor.authParams.layersCount = layers.length;
    }

    return { ...state, constructor };
};

const onToggleLevelSwitch = (state, action) => {
    const { pageId } = state;
    const AuthControlData = { ...state.AuthControlData };
    const { level, auth_title, radio, isChecked } = action.payload;

    if (isChecked) {
        AuthControlData[pageId][level - 1] = [
            ...AuthControlData[pageId][level - 1].map((item) => {
                if (item.auth_title !== auth_title) {
                    return radio ? { ...item, disabled: true } : item.radio ? { ...item, disabled: true } : item;
                } else {
                    return { ...item, checked: true };
                }
            }),
        ];

        Object.keys(AuthControlData[pageId])
            .filter((key) => key !== 'layersCount' && key !== 'globalOptions')
            .forEach((key) => {
                if (Number(key) !== level - 1) {
                    AuthControlData[pageId][key] = [
                        ...AuthControlData[pageId][key].map((item) => {
                            if (item.auth_title !== auth_title) {
                                return item;
                            } else {
                                return { ...item, disabled: true };
                            }
                        }),
                    ];
                }
            });
    } else {
        const checks = AuthControlData[pageId][level - 1].reduce((acc, item) => {
            return item.checked && !item.radio ? acc + 1 : acc;
        }, 0);

        const alreadyChecked = [];
        Object.keys(AuthControlData[pageId])
            .filter((key) => key !== 'layersCount' && key !== 'globalOptions')
            .forEach((key) => {
                if (Number(key) !== level - 1) {
                    AuthControlData[pageId][key].forEach((item) => {
                        if (item.checked) {
                            alreadyChecked.push(item.auth_title);
                        }
                    });
                }
            });

        AuthControlData[pageId][level - 1] = [
            ...AuthControlData[pageId][level - 1].map((item) => {
                if (item.auth_title !== auth_title) {
                    if (alreadyChecked.includes(item.auth_title)) return item;
                    return radio ? { ...item, disabled: false } : item.radio && checks < 2 ? { ...item, disabled: false } : item;
                } else {
                    return { ...item, checked: false };
                }
            }),
        ];

        Object.keys(AuthControlData[pageId])
            .filter((key) => key !== 'layersCount' && key !== 'globalOptions')
            .forEach((key) => {
                if (Number(key) !== level - 1) {
                    const checks1 = AuthControlData[pageId][key].reduce((acc, item) => {
                        return item.checked ? acc + 1 : acc;
                    }, 0);
                    AuthControlData[pageId][key] = [
                        ...AuthControlData[pageId][key].map((item) => {
                            if (item.auth_title !== auth_title) {
                                return item;
                            } else {
                                return { ...item, disabled: checks1 > 0 ? true : false };
                            }
                        }),
                    ];
                }
            });
    }
    return { ...state, AuthControlData };
};

const setMainLocation = (state, action) => {
    const { mainLocation } = action.payload;
    return { ...state, mainLocation };
};

const toggleUploadModal = (state, action) => {
    const { show } = action.payload;
    return { ...state, showImageUploadModal: show };
};

const toggleDeletePageModal = (state, action) => {
    const { show } = action.payload;
    return { ...state, showDeletePageModal: show };
};

const setPalette = (state, action) => {
    const { palette } = action.payload;
    return { ...state, palette };
};

const PageConstructor = (state, action) => {
    switch (action.type) {
        case 'setQueryParams':
            return setQueryParams(state, action);
        case 'setLoginResponse':
            return setLoginResponse(state, action);
        case 'setPages':
            return setPages(state, action);
        case 'getPages':
            return getPages(state, action);
        case 'setBlockTypes':
            return setBlockTypes(state, action);
        case 'setMedia':
            return setMedia(state, action);
        case 'toggleCollapse':
            return toggleCollapse(state, action);
        case 'handleChangeBackColor':
            return handleChangeBackColor(state, action);
        case 'setConstructor':
            return setConstructor(state, action);
        case 'changeBgImage':
            return changeBgImage(state, action);
        case 'onAddBlockClick':
            return onAddBlockClick(state, action);
        case 'onConstructorBlockClick':
            return onConstructorBlockClick(state, action);
        case 'onMainBGClick':
            return onMainBGClick(state, action);
        case 'handleChangeBlockBGColor':
            return handleChangeBlockBGColor(state, action);
        case 'changeBlockBgImage':
            return changeBlockBgImage(state, action);
        case 'onBlockContainCheckboxChange':
            return onBlockContainCheckboxChange(state, action);
        case 'onPageContainCheckboxChange':
            return onPageContainCheckboxChange(state, action);
        case 'toggleBlockParamsCollapse':
            return toggleBlockParamsCollapse(state, action);
        case 'togglePageParamsCollapse':
            return togglePageParamsCollapse(state, action);
        case 'setWindowDimensions':
            return setWindowDimensions(state, action);
        case 'setCurrentPage':
            return setCurrentPage(state, action);
        case 'onDeleteImageButtonClick':
            return onDeleteImageButtonClick(state, action);
        case 'onFormatChange':
            return onFormatChange(state, action);
        case 'fillDefaultTextParams':
            return fillDefaultTextParams(state, action);
        case 'fillDefaultStyle':
            return fillDefaultStyle(state, action);
        case 'onBorderInputChange':
            return onBorderInputChange(state, action);
        case 'onDeleteBlockClick':
            return onDeleteBlockClick(state, action);
        case 'onBlockMoveClick':
            return onBlockMoveClick(state, action);
        case 'onWebLinkChange':
            return onWebLinkChange(state, action);
        case 'toggleCreateNewModal':
            return toggleCreateNewModal(state, action);
        case 'changePageTitle':
            return changePageTitle(state, action);
        case 'toggleShowAuthParams':
            return toggleShowAuthParams(state, action);
        case 'fillDefaultAuthParams':
            return fillDefaultAuthParams(state, action);
        case 'onChangeAuthButtonBackgroundColor':
            return onChangeAuthButtonBackgroundColor(state, action);
        case 'onChangeAuthBackgroundColor':
            return onChangeAuthBackgroundColor(state, action);
        case 'onInternetButtonTextChange':
            return onInternetButtonTextChange(state, action);
        case 'onToggleAuthLandingShow':
            return onToggleAuthLandingShow(state, action);
        case 'toggleAuthOptionsModal':
            return toggleAuthOptionsModal(state, action);
        case 'onToggleLevelSwitch':
            return onToggleLevelSwitch(state, action);
        case 'handleAuthOptionsChange':
            return handleAuthOptionsChange(state, action);
        case 'handleGlobalAuthOptionsChange':
            return handleGlobalAuthOptionsChange(state, action);
        case 'toggleImgPickerModal':
            return toggleImgPickerModal(state, action);
        case 'handleImgPickerModalClick':
            return handleImgPickerModalClick(state, action);
        case 'clearImgPickerModal':
            return clearImgPickerModal(state, action);
        case 'onSaveAuthModalClick':
            return onSaveAuthModalClick(state, action);
        case 'onChangeLayersCount':
            return onChangeLayersCount(state, action);
        case 'showSpinner':
            return showSpinner(state, action);
        case 'setMainLocation':
            return setMainLocation(state, action);
        case 'toggleUploadModal':
            return toggleUploadModal(state, action);
        case 'toggleDeletePageModal':
            return toggleDeletePageModal(state, action);
        case 'setPalette':
            return setPalette(state, action);

        default:
            return state;
    }
};

export default PageConstructor;
