import React, { useState, useContext } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components/macro';
import Context from '../../../context';

const SmallColorPicker = React.memo(({ color, onChangeColor, width='100%' }) => {
    const { state, dispatch } = useContext(Context);
    const { selectedBlock, defaultTextParams } = state;
    const [displayColorPicker, setDisplayColorPicker] = useState(false);

    const handleClick = () => {
        setDisplayColorPicker(prev => !displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleChange = formatType => color => {
        const { r, g, b, a } = color.rgb;
        const backgroundColor = `rgba(${r}, ${g}, ${b}, ${a})`;
        onChangeColor(formatType, backgroundColor, selectedBlock);
    };

    const styles = {
        container: {
            width: width,
            height: '41px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '5px'
        },
        swatch: {
            width: '100%',
            height: '100%',
            padding: '5px',
            background: '#fff',
            borderRadius: '1px',
            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
            display: 'inline-block',
            cursor: 'pointer'
        },
        popover: {
            position: 'absolute',
            zIndex: '2'
        },
        cover: {
            position: 'fixed',
            top: '0px',
            right: '0px',
            bottom: '0px',
            left: '0px'
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.swatch} onClick={handleClick}>
                <Color bgColor={color} />
            </div>
            {displayColorPicker ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color={color} onChange={handleChange('color')} />
                </div>
            ) : null}
        </div>
    );
});

export default SmallColorPicker;

const Color = styled.div`
    background-color: ${p => p.bgColor};
    width: 100%;
    height: 100%;
    border-radius: 2px;
`;
