import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from '../../button';
import Switch from '../../switch';
import { useTranslation } from 'react-i18next';

const FacebookOptions = React.memo(({ auth_title, options, level, ...props }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { pageId, pathToFiles } = state;

    const toggleAutopost = e => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, autopost: e.target.checked } }
        });
    };

    const onTextChange = name => e => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, [`autopost_${name}`]: e.target.value } }
        });
    };

    const handleShowModal = (options, isPicture) => e => {
        if (isPicture) {
            dispatch({
                type: 'clearImgPickerModal',
                payload: { auth_title, level }
            });
        } else {
            dispatch({
                type: 'toggleImgPickerModal',
                payload: { show: true, options, auth_title, level }
            });
        }
    };

    const imgButtonStyle = {
        width: '100%',
        height: 40,
        margin: '10px 0'
    };

    const isPicture = options.autopost_img && options.autopost_img !== '';

    return (
        <CollapsedContainer>
            <InputContainer>
                <FormControlLabel
                    control={<Switch checked={options.autopost} onChange={toggleAutopost} />}
                    label={t('Автопост')}
                />
                <Wrapper>
                    {options.autopost && (
                        <Wrapper>
                            <TextField
                                label={t('Ссылка')}
                                multiline
                                fullWidth
                                value={options.autopost_url || ''}
                                onChange={onTextChange('url')}
                                margin="dense"
                            />
                            <TextField
                                label={t('Заголовок')}
                                multiline
                                fullWidth
                                value={options.autopost_title || ''}
                                onChange={onTextChange('title')}
                                margin="dense"
                            />
                            <TextField
                                label={t('Описание')}
                                multiline
                                fullWidth
                                value={options.autopost_description || ''}
                                onChange={onTextChange('description')}
                                margin="dense"
                            />
                            {isPicture && (
                                <ImgContainer>
                                    <Img src={`${pathToFiles}${options.autopost_img}`} />
                                </ImgContainer>
                            )}

                            <Button
                                style={imgButtonStyle}
                                variant="contained"
                                size="medium"
                                color={isPicture ? 'secondary' : 'primary'}
                                onClick={handleShowModal(options, isPicture)}
                            >
                                {isPicture ? t('Удалить изображение') : t('Добавить изображение')}
                            </Button>
                        </Wrapper>
                    )}
                </Wrapper>
            </InputContainer>
        </CollapsedContainer>
    );
});

export default FacebookOptions;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const CollapsedContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const InputContainer = React.memo(styled.div`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 0 10px 5px 20px;
    padding-right: 10px;
    padding-left: 10px;
    span {
        display: flex;
        align-items: center;
    }
`);

const Img = styled.img`
    object-fit: contain;
    height: 100%;
    width: 100%;
`;

const ImgContainer = styled.div`
    cursor: pointer;
    overflow: hidden;
    height: 200px;
    width: 100%;
    transition: 1s;
    :hover {
        transform: scale(1.1);
    }
`;
