import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Radio from '../../radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Switch from '../../switch';
import { useTranslation } from 'react-i18next';

const VkontakteOptions = React.memo(({ auth_title, options, level, ...props }) => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { pageId } = state;

    const toggleAutopost = (e) => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, autopost: e.target.checked } },
        });
    };

    const toggleRepost = (e) => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, repost: e.target.value === 'repost' ? true : false } },
        });
    };

    const onMessageChange = (e) => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, autopost_message: e.target.value } },
        });
    };

    const onDetailsChange = (e) => {
        dispatch({
            type: 'handleAuthOptionsChange',
            payload: { pageId, level, auth_title, options: { ...options, autopost_details: e.target.value } },
        });
    };

    const radioGroupStyle = {
        display: 'flex',
        flexDirection: 'row',
    };

    return (
        <CollapsedContainer>
            <InputContainer>
                <FormControlLabel control={<Switch checked={options.autopost} onChange={toggleAutopost} />} label={t('Автопост')} />
                <Wrapper>
                    {options.autopost && (
                        <RadioGroup
                            // className={classes.group}
                            style={radioGroupStyle}
                            value={options.repost ? 'repost' : 'user'}
                            onChange={toggleRepost}
                        >
                            <FormControlLabel
                                value="user"
                                control={<Radio checked={!options.repost} />}
                                label={t('От имени пользователя')}
                            />
                            <FormControlLabel
                                value="repost"
                                control={<Radio checked={options.repost} />}
                                label={t('Репост')}
                            />
                        </RadioGroup>
                    )}
                    {options.autopost && !options.repost ? (
                        <Wrapper>
                            <TextField
                                label={t('Сообщение')}
                                multiline
                                fullWidth
                                value={options.autopost_message || ''}
                                onChange={onMessageChange}
                                margin="dense"
                            />
                            <TextField
                                label={t('Приложение')}
                                multiline
                                fullWidth
                                value={options.autopost_details || ''}
                                onChange={onDetailsChange}
                                margin="dense"
                            />
                        </Wrapper>
                    ) : options.autopost && options.repost ? (
                        <TextField
                            label={t('ID Поста')}
                            multiline
                            fullWidth
                            value={options.autopost_details || ''}
                            onChange={onDetailsChange}
                            margin="dense"
                        />
                    ) : null}
                </Wrapper>
            </InputContainer>
        </CollapsedContainer>
    );
});

export default VkontakteOptions;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const CollapsedContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const InputContainer = React.memo(styled.div`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 0 10px 5px 20px;
    padding-right: 10px;
    padding-left: 10px;
    span {
        display: flex;
        align-items: center;
    }
`);
