import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { SketchPicker } from 'react-color';
import Context from '../../../context';
import CollapseSection from './collapse_section';
import MediaPicker from './media_picker';

const PageParams = React.memo(({ name, id, children }) => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { backgroundColor } = state.constructor ? state.constructor : '#fff';
    const { pathToFiles, constructor, media } = state;
    const { accordion } = state.pageParams;

    const handleChangeBackColor = color => {
        dispatch({
            type: 'handleChangeBackColor',
            payload: color.rgb
        });
    };

    const onImgContainerClick = item => {
        dispatch({
            type: 'changeBgImage',
            payload: item
        });
    };

    const BGSize =
        constructor && constructor.media && constructor.media[0] && constructor.media[0].BGSize
            ? constructor.media[0].BGSize
            : 'cover';
    const isContainChecked = BGSize === 'contain';

    const onContainCheckboxChange = e => {
        dispatch({
            type: 'onPageContainCheckboxChange',
            payload: { checked: e.target.checked }
        });
    };

    const toggle = id => {
        dispatch({
            type: 'togglePageParamsCollapse',
            payload: id
        });
    };

    const onDeleteImageButtonClick = () => {
        dispatch({
            type: 'onDeleteImageButtonClick',
            payload: undefined
        });
    };

    return (
        <Container>
            <CollapseSection id={1} isOpen={accordion[1]} toggle={toggle} name={t('Цвет фона')}>
                <SketchPicker color={backgroundColor} onChangeComplete={handleChangeBackColor} />
            </CollapseSection>
            <CollapseSection id={2} isOpen={accordion[2]} toggle={toggle} name={t('Изображение')}>
                <MediaPicker
                    currentImg={`${pathToFiles}${
                        constructor && constructor.media && constructor.media[0] ? constructor.media[0].url : null
                    }`}
                    onImgContainerClick={onImgContainerClick}
                    isContainChecked={isContainChecked}
                    onContainCheckboxChange={onContainCheckboxChange}
                    onDeleteImageButtonClick={onDeleteImageButtonClick}
                />
            </CollapseSection>
        </Container>
    );
});

export default PageParams;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;
