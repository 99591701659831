import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components/macro';
import Context from '../context';

const MyRadio = React.memo((props) => {
    const { state } = React.useContext(Context);
    const { palette } = state;
    return <StyledCheckbox palette={palette} {...props} />;
});

export default MyRadio;

const StyledCheckbox = styled(Checkbox)`
    padding: ${(p) => {
        if (p.padding) {
            return p.padding + ' !important';
        }
    }};
    /* color: ${(p) => {
        return p.palette.themePrimary + '!important';
    }}; */
    .MuiSvgIcon-root {
        color: ${(p) => {
            if (p.checked) {
                return p.palette.themePrimary + '!important';
            }
        }};
    }
`;
