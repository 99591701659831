import React, { useContext, useMemo } from 'react';
import cx from 'classnames';
import styled from 'styled-components/macro';
import Collapse from '@kunukn/react-collapse';
import Context from '../../../context';
import './app.css';

const CollapseSection = React.memo(({ name, isOpen, id, children, isMain, ...props }) => {
    const { state, dispatch } = useContext(Context);
    const toggle = (id) => (e) => {
        props.toggle(id);
    };
    const mh = state.windowDimensions.height - Object.keys(state.controlPanel.accordion).length * 45 - 50;
    let headerBgColor = '#eee';
    let textColor = '#000';
    let borderRadius = '0px';
    if (isMain) {
        if (isOpen) {
            headerBgColor = state?.palette?.themePrimary;
            textColor = '#eee';
        }
    } else {
        borderRadius = '30px';
        if (isOpen) {
            headerBgColor = state?.palette?.themePrimary;
            textColor = '#eee';
        } else {
            headerBgColor = '#ccc';
        }
    }

    return useMemo(
        () => (
            <Container>
                <Header
                    borderRadius={borderRadius}
                    headerBgColor={headerBgColor}
                    textColor={textColor}
                    className={'app__toggle'}
                    onClick={toggle(id)}
                >
                    <span className="app__toggle-text">{name}</span>
                    <div className="rotate90">
                        <svg className={cx('icon', { 'icon--expanded': isOpen })} viewBox="6 0 12 24">
                            <polygon points="8 0 6 1.8 14.4 12 6 22.2 8 24 18 12" />
                        </svg>
                    </div>
                </Header>
                <Collapse isOpen={isOpen}>
                    <InnerContainer mh={mh}>{children}</InnerContainer>
                </Collapse>
            </Container>
        ),
        [borderRadius, children, headerBgColor, id, isOpen, mh, name, toggle]
    );
});

export default CollapseSection;

const Container = styled.div`
    padding: 2px 0;
    overflow-y: scroll;
    width: 100%;
`;

const Header = styled.div`
    background-color: ${(p) => p.headerBgColor};
    color: ${(p) => p.textColor};
    min-height: 40px;
    border-radius: ${(p) => p.borderRadius};
    svg {
        fill: ${(p) => p.textColor};
    }
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: ${(p) => p.mh + 'px'};
    padding: 10px;
    overflow-y: auto;
`;
