import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Context from '../../../context';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '../../switch';

import AuthParams from './auth_params';

const Auth = React.memo(() => {
    const { t } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { showAuthParams, constructor } = state;

    const toggleShowAuthParams = () => {
        dispatch({
            type: 'toggleShowAuthParams',
        });
    };

    return (
        constructor && (
            <Container>
                <FormControlLabel
                    control={<Switch  checked={showAuthParams} onChange={toggleShowAuthParams} value="checkedB" />}
                    label={t('Авторизационная страница')}
                />
                {showAuthParams && <AuthParams />}
            </Container>
        )
    );
});

export default Auth;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
`;
