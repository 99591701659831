import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Fab from '@material-ui/core/Fab';
import Input from '@material-ui/core/Input';
import DeleteIcon from '@material-ui/icons/DeleteForever';
import Snackbar from '@material-ui/core/Snackbar';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Share from '@material-ui/icons/Share';
import BackArrowIcon from '@material-ui/icons/KeyboardBackspace';
import ApplyIcon from '@material-ui/icons/Done';
import CopyIcon from '@material-ui/icons/Filter2';
import Tooltip from '@material-ui/core/Tooltip';
import { API, onClickSave } from '../../tools';
import Context from '../../context';

const Header = React.memo(() => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const textAreaRef = useRef(null);
    const {
        palette,
        pageTitle,
        pageUUID,
        constructor,
        token,
        pageId,
        pages,
        showAuthParams,
        changedPages,
        loginResponse,
        pathToFiles,
        mainLocation,
    } = state;
    const [newTitle, setNewTitle] = useState('');
    const [active, setActive] = useState(false);
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    useEffect(() => {
        setNewTitle(pageTitle);
    }, [pageTitle, pages]);

    useEffect(() => {
        setActive(pageTitle !== newTitle);
    }, [pageTitle, newTitle, pages]);

    const onClickShowPagePreviews = () => {
        const dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : Screen.left;
        const dualScreenTop = window.screenTop != undefined ? window.screenTop : Screen.top;

        const width = window.innerWidth
            ? window.innerWidth
            : document.documentElement.clientWidth
            ? document.documentElement.clientWidth
            : Screen.width;
        const height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : Screen.height;
        const left = width / 2 - 440 / 2 + dualScreenLeft;
        const top = height / 2 - 700 / 2;
        window.open(
            `${pathToFiles}/previews/` + pageUUID + '/',
            'Ratting',
            'width=440,height=700,center,status=0,scrollbars=1,resizable=true' + ', top=' + top + ', left=' + left
        );
    };

    const copyToClipboard = (el) => {
        const oldContentEditable = el.contentEditable;
        const oldReadOnly = el.readOnly;

        try {
            el.contentEditable = 'true'; // specific to iOS
            el.readOnly = false;
            copyNodeContentsToClipboard(el);
        } finally {
            el.contentEditable = oldContentEditable;
            el.readOnly = oldReadOnly;
        }
    };

    const copyNodeContentsToClipboard = (el) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(el);
        selection.removeAllRanges();

        selection.addRange(range);
        el.setSelectionRange(0, 999999);

        document.execCommand('copy');
        setSnackBarOpen(true);
    };

    const onClickCopyToClipboard = () => {
        const text = `${pathToFiles}/pages/${pageUUID}/`;
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(text);
                setSnackBarOpen(true);
            } else if (window.clipboardData) {
                // IE
                window.clipboardData.setData('text', text);
                setSnackBarOpen(true);
            } else {
                copyToClipboard(textAreaRef.current);
            }
        } catch (e) {}
    };

    const onClickDelete = async () => {
        dispatch({
            type: 'toggleDeletePageModal',
            payload: { show: true },
        });

        // dispatch({
        //     type: 'showSpinner',
        //     payload: true,
        // });
        // try {
        //     const url = `${pathToFiles}/content/delete_page/`;
        //     const formData = new FormData();
        //     formData.append('access_token', token);
        //     formData.append('page_id', pageId);
        //     await axios.post(url, formData);
        //     dispatch({
        //         type: 'showSpinner',
        //         payload: false,
        //     });
        //     window.location.reload(true);
        //     // API(`${pathToFiles}/content/get_pages/?access_token=${state.token}`, undefined, 'getPages', dispatch);
        // } catch (error) {
        //     console.warn(error);
        //     dispatch({
        //         type: 'showSpinner',
        //         payload: false,
        //     });
        // }
    };

    const onClickCreateNew = (e) => {
        const cursorCoords = {
            x: e.clientX,
            y: e.clientY,
        };
        dispatch({
            type: 'toggleCreateNewModal',
            payload: { show: true, cursorCoords },
        });
    };

    const onInputChange = (e) => {
        setNewTitle(e.target.value);
    };

    const onPageTitleChangeClick = () => {
        if (!active) return;
        onClickSave(pathToFiles, newTitle, dispatch, token, constructor, pageId, showAuthParams)
            .then(() => {
                dispatch({
                    type: 'changePageTitle',
                    payload: { newTitle },
                });
            })
            .catch((err) => console.warn(err));
    };

    const onClickReturnBack = () => {
        window.location.replace(mainLocation, '_blank');
    };

    const onClickCopy = async () => {
        dispatch({
            type: 'showSpinner',
            payload: true,
        });
        try {
            const url = `${pathToFiles}/content/copy_page/`;
            const formData = new FormData();
            formData.append('access_token', token);
            formData.append('page_id', pageId);
            formData.append('API_HOST', '');
            await axios.post(url, formData);
            API(`${pathToFiles}/content/get_pages/?access_token=${token}`, undefined, 'setPages', dispatch);
            dispatch({
                type: 'showSpinner',
                payload: false,
            });
        } catch (error) {
            console.warn(error);
            dispatch({
                type: 'showSpinner',
                payload: false,
            });
        }
    };

    const disabled = changedPages[pageId] ? changedPages[pageId] : false;
    const logoURL =
        loginResponse.white_label_settings && loginResponse.white_label_settings.small_logo_url
            ? loginResponse.white_label_settings.small_logo_url
            : '';
    const logoBgColor = palette?.themePrimary || '#fff';
    const hoveredBgColor = palette?.themeDark || '#fff';

    const handleClose = (event, reason) => {
        setSnackBarOpen(false);
    };

    return (
        <Container>
            <ClipBoardTextArea ref={textAreaRef} value={`${pathToFiles}/pages/${pageUUID}/`} />
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={snackBarOpen}
                autoHideDuration={2000}
                onClose={handleClose}
                message={t('Скопировано')}
                // action={
                //     <React.Fragment>
                //         <Button color="secondary" size="small" onClick={handleClose}>
                //             UNDO
                //         </Button>
                //         <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                //             <CloseIcon fontSize="small" />
                //         </IconButton>
                //     </React.Fragment>
                // }
            />
            <Logo logoURL={logoURL} logoBgColor={logoBgColor} />
            <ButtonContainer>
                <Tooltip title={t('Вернуться в кабинет')}>
                    <CustomFab
                        bgcolor={logoBgColor}
                        hoveredbgcolor={hoveredBgColor}
                        color="primary"
                        size="small"
                        onClick={onClickReturnBack}
                    >
                        <BackArrowIcon />
                    </CustomFab>
                </Tooltip>
            </ButtonContainer>
            <ButtonContainer>
                <Tooltip title={t('Создать страницу')}>
                    <CustomFab
                        bgcolor={logoBgColor}
                        hoveredbgcolor={hoveredBgColor}
                        color="secondary"
                        size="small"
                        onClick={onClickCreateNew}
                    >
                        <AddIcon />
                    </CustomFab>
                </Tooltip>
            </ButtonContainer>
            <ButtonContainer>
                <Tooltip title={t('Скопировать страницу')}>
                    <CustomFab bgcolor={logoBgColor} hoveredbgcolor={hoveredBgColor} color="secondary" size="small" onClick={onClickCopy}>
                        <CopyIcon />
                    </CustomFab>
                </Tooltip>
            </ButtonContainer>
            <ButtonContainer>
                <Tooltip title={t('Сохранить страницу')}>
                    <CustomFab
                        bgcolor={!disabled ? '#ddd' : logoBgColor}
                        hoveredbgcolor={hoveredBgColor}
                        size="small"
                        disabled={!disabled}
                        onClick={() => onClickSave(pathToFiles, pageTitle, dispatch, token, constructor, pageId, showAuthParams)}
                    >
                        <SaveIcon />
                    </CustomFab>
                </Tooltip>
            </ButtonContainer>
            <ButtonContainer>
                <Tooltip title={t('Удалить страницу')}>
                    <CustomFab bgcolor={logoBgColor} hoveredbgcolor={hoveredBgColor} color="secondary" size="small" onClick={onClickDelete}>
                        <DeleteIcon />
                    </CustomFab>
                </Tooltip>
            </ButtonContainer>
            <InputContainer>
                <Tooltip title={t('Изменить название')}>
                    <Input type="text" value={newTitle} disableUnderline onChange={onInputChange} />
                </Tooltip>
                <StyledBtn bgcolor={logoBgColor} hoveredbgcolor={hoveredBgColor} active={active} onClick={onPageTitleChangeClick}>
                    <ApplyIcon color="inherit" />
                </StyledBtn>
            </InputContainer>
            <ButtonContainer>
                <Tooltip title={t('Предварительный просмотр')}>
                    <CustomFab
                        bgcolor={disabled ? '#ddd' : logoBgColor}
                        hoveredbgcolor={hoveredBgColor}
                        color="secondary"
                        size="small"
                        disabled={disabled}
                        onClick={onClickShowPagePreviews}
                    >
                        <VisibilityIcon />
                    </CustomFab>
                </Tooltip>
            </ButtonContainer>
            <ButtonContainer>
                <Tooltip title={t('Копировать ссылку на страницу в буфер обмена')}>
                    <CustomFab
                        bgcolor={disabled ? '#ddd' : logoBgColor}
                        hoveredbgcolor={hoveredBgColor}
                        color="secondary"
                        size="small"
                        disabled={disabled}
                        onClick={onClickCopyToClipboard}
                    >
                        <Share />
                    </CustomFab>
                </Tooltip>
            </ButtonContainer>
        </Container>
    );
});

export default Header;

const CustomFab = styled(Fab)`
    background-color: ${(p) => p.bgcolor + ' !important'};
    :hover {
        background-color: ${(p) => (p.hoveredbgcolor + ' !important': '#ccc')};
    }
`;

const StyledBtn = styled.div`
    color: white;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${(p) => (p.active ? p.bgcolor : '#ccc')};
    :hover {
        background-color: ${(p) => (p.active ? p.hoveredbgcolor : '#ccc')};
    }
    border-radius: 50%;
`;

const Container = styled.div`
    width: 100%;
    /* background-color: rgb(75, 183, 119); */
    background-color: #ddd;
    display: flex;
    align-items: center;
`;

const ButtonContainer = styled.div`
    margin-left: 10px;
`;

const ClipBoardTextArea = styled.textarea`
    position: absolute;
    left: -1000px;
`;

const Logo = styled.div`
    width: 320px;
    height: 50px;
    background-image: url(${(p) => p.logoURL});
    background-color: ${(p) => p.logoBgColor};
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
`;

const InputContainer = React.memo(styled.div`
    margin-left: 10px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 30px;
    padding: 3px 4px 3px 10px;
    display: flex;
    align-items: center;
`);
