import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Context from '../../../context';

const ContainCheckbox = React.memo(props => {
    const { state, dispatch } = useContext(Context);
    const { media } = state;
    const { t, i18n } = useTranslation();
    return media.user_items ? (
        <Container>
            <Label>
                <Input type={'checkbox'} {...props} />
                <span>{t('Вместить целиком')}</span>
            </Label>
        </Container>
    ) : null;
});

export default ContainCheckbox;

const Container = styled.div`
`;

const Label = styled.label`
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const Input = styled.input`
    margin-right: 5px;
`;
