import React, { useContext, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Button from './button';

import Context from '../context';

const DeletePageModal = React.memo(() => {
    const { state, dispatch } = useContext(Context);
    const { showDeletePageModal, cursorCoords, token, pathToFiles, pageId } = state;
    const { t } = useTranslation();

    const closeModal = async () => {
        dispatch({
            type: 'toggleDeletePageModal',
            payload: { show: false },
        });
    };

    const applyModal = async () => {
        dispatch({
            type: 'toggleDeletePageModal',
            payload: { show: false },
        });
        dispatch({
            type: 'showSpinner',
            payload: true,
        });
        try {
            const url = `${pathToFiles}/content/delete_page/`;
            const formData = new FormData();
            formData.append('access_token', token);
            formData.append('page_id', pageId);
            await axios.post(url, formData);
            dispatch({
                type: 'showSpinner',
                payload: false,
            });
            window.location.reload(true);
            // API(`${pathToFiles}/content/get_pages/?access_token=${state.token}`, undefined, 'getPages', dispatch);
        } catch (error) {
            console.warn(error);
            dispatch({
                type: 'showSpinner',
                payload: false,
            });
        }
    };

    const onContainerClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Overlay show={showDeletePageModal} onClick={closeModal}>
            <Container cursorCoords={cursorCoords} onClick={onContainerClick}>
                <Main>
                    <label htmlFor="raised-button-file">{t('Удалить страницу?')}</label>
                </Main>
                <div></div>
                <Footer>
                    <Button variant="outlined" color="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button margin={'0 0 0 10px'} variant="contained" color="primary" onClick={applyModal}>
                        OK
                    </Button>
                </Footer>
            </Container>
        </Overlay>
    );
});

export default DeletePageModal;

const Main = styled.div`
    display: flex;
`;
const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 30;
    display: ${(p) => (p.show ? 'block' : 'none')};
`;
const Container = styled.div`
    padding: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -70px;
    margin-left: -150px;
    width: 300px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
`;
