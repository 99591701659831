import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import { blockSize, subBlockSize } from '../../../tools';

import Block from '../../constructor/components/block';

const AddBlock = React.memo(({ name, id, children }) => {
    const { state, dispatch } = useContext(Context);
    const border = {
        width: 2,
        color: '#777'
    };

    const onAddBlockClick = index => e => {
        dispatch({
            type: 'onAddBlockClick',
            payload: index
        });
    };

    return (
        <Ul>
            {state.blockTypes.map((block, i) => {
                const size = blockSize({ width: 140 }, block, border.width);
                return (
                    <li key={`${block.name}-${i}`} onClick={onAddBlockClick(i)}>
                        <Block
                            interactive={false}
                            border={border}
                            position={'relative'}
                            size={size}
                        >
                            {block.details.subblocks &&
                                block.details.subblocks.map((subblock, j) => {
                                    
                                    return (
                                        <Block
                                            key={`${subblock.width}-${i}-${j}`}
                                            interactive={false}
                                            border={border}
                                            position={'absolute'}
                                            size={subBlockSize(size, subblock, border.width)}
                                        />
                                    );
                                })}
                        </Block>
                    </li>
                );
            })}
        </Ul>
    );
});

export default AddBlock;

const Ul = styled.ul`
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: 0px;
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    li {
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        cursor: pointer;
        background-color: #eee;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-shrink: 0;
        flex-grow: 1;
        transition: 0.3s ease-in-out;
        :hover {
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
            transform: scale(1.02);
            background-color: #ddd;
            background-color: #ddd;
        }
        :active {
            transform: scale(1);
            background-color: #ccc;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
        }
    }
`;
