import React, { useContext, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components/macro';
import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Clear';
import ApplyIcon from '@material-ui/icons/Done';
import { withStyles } from '@material-ui/core/styles';

import { API } from '../tools';
import Context from '../context';

const CreateNewModal = React.memo(() => {
    const { state, dispatch } = useContext(Context);
    const { showCreateNewModal, cursorCoords, token, pathToFiles } = state;
    const [newPageName, setNewPageName] = useState('');

    const closeModal = async () => {
        dispatch({
            type: 'toggleCreateNewModal',
            payload: { show: false, cursorCoords }
        });
        setNewPageName('');
    };

    const applyModal = async () => {
        if (newPageName === '') return;
        const cursorCoords = {
            x: 0,
            y: 0
        };
        try {
            const url = `${pathToFiles}/content/create_page/`;
            const formData = new FormData();
            formData.append('access_token', token);
            formData.append('page_title', newPageName);
            formData.append('API_HOST', '');
            const response = await axios.post(url, formData);
            API(`${pathToFiles}/content/get_pages/?access_token=${state.token}`, undefined, 'setPages', dispatch);
        } catch (error) {
            console.warn(error);
        }
        dispatch({
            type: 'toggleCreateNewModal',
            payload: { show: false, cursorCoords }
        });
        setNewPageName('');
    };

    const onChange = e => {
        setNewPageName(e.target.value);
    };

    const onContainerClick = e => {
        e.stopPropagation();
    };

    const inputStyle = {
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
        padding: '10px 30px'
    };

    const closeButtonStyle = {
        flexShrink: 0,
        marginLeft: 5,
        marginRight: 5
    };

    const StyledBtn = withStyles({
        root: {
            flexShrink: 0,
            marginRight: 5,
            backgroundColor: 'rgb(50,205,50)',
            '&:hover': {
                backgroundColor: 'rgb(34,139,34)'
            }
        }
    })(Fab);

    return (
        <Overlay show={showCreateNewModal} onClick={closeModal}>
            <Container cursorCoords={cursorCoords} onClick={onContainerClick}>
                <Input
                    disableUnderline
                    style={inputStyle}
                    placeholder="New page name"
                    value={newPageName}
                    onChange={onChange}
                    margin="dense"
                    autoFocus
                    fullWidth
                />
                <Fab style={closeButtonStyle} color="secondary" size="small" onClick={closeModal}>
                    <CloseIcon />
                </Fab>
                <StyledBtn color="primary" size="small" onClick={applyModal}>
                    <ApplyIcon />
                </StyledBtn>
            </Container>
        </Overlay>
    );
});

export default CreateNewModal;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    /* background-color: rgba(0, 0, 0, 0.5); */
    z-index: 30;
    display: ${p => (p.show ? 'block' : 'none')};
`;
const Container = styled.div`
    padding: 5px;
    position: absolute;
    top: ${p => p.cursorCoords.y + 'px'};
    left: ${p => p.cursorCoords.x + 'px'};
    width: 300px;
    height: 70px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
`;
