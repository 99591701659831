import React, { useContext, useMemo, useEffect } from 'react';
import styled from 'styled-components/macro';
import Constructor from '../constructor';

import img from './img/iphone1.png';
// import eyebrow from './img/brov.png';

const Preview = React.memo(() => {
    return (
        <Container>
            <InnerContainer>
                {/* <Eyebrow /> */}
                <Phone img={img} width={330} ratio={2.08} />
                <Constructor isPreview={true} />
            </InnerContainer>
        </Container>
    );
});

export default Preview;

// const Eyebrow = styled.div`
//     background: transparent url(${eyebrow}) no-repeat center;
//     background-size: contain;
//     position: absolute;
//     z-index: 20;
//     width: 320px;
//     height: 40px;
//     top: -8px;
// `;

const InnerContainer = styled.div`
    padding: 0;
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.25);
    border-radius: 42px;
    position: relative;
    width: 320px;
    height: 650px;
`;
const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const Phone = styled.div`
    pointer-events: none;
    border-radius: 50px;
    width: ${p => p.width + 'px'};
    height: ${p => p.width * p.ratio + 'px'};
    background: transparent url(${props => props.img}) no-repeat center;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -20px;
    left: -5px;
    z-index: 15;
`;
