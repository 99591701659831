import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Context from '../../../context';

const GlobalOptions = React.memo(() => {
    const { t, i18n } = useTranslation();
    const { state, dispatch } = useContext(Context);
    const { pageId, AuthControlData, globalOptionsLabelsCatalog } = state;

    const onChange = (name, type) => e => {
        e.stopPropagation();
        if (name === 'traficVolume' && Number(e.target.value) > 4000) return;
        dispatch({
            type: 'handleGlobalAuthOptionsChange',
            payload: { name, value: e.target.value, type }
        });
    };



    return (
        <Container>
            {AuthControlData[pageId]
                ? AuthControlData[pageId].globalOptions.map((item, i) => {
                      const inputProps = { min: 0 };
                      if (item.name === 'traficVolume') {
                          inputProps.max = '4000';
                      }
                      const type = globalOptionsLabelsCatalog[item.name].type;

                      return (
                          <TextField
                              key={i}
                              label={t(globalOptionsLabelsCatalog[item.name].label)}
                              fullWidth
                              type={type}
                              inputProps={inputProps}
                              value={item.value}
                              onChange={onChange(item.name, type)}
                              margin="dense"
                          />
                      );
                  })
                : null}
        </Container>
    );
});

export default GlobalOptions;

const Container = styled.div`
    width: 100%;
    padding: 5px;
    margin-right: 10px;
    background-color: whitesmoke;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1%;
`;
