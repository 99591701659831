import React from 'react';

import { generateInitialAuthControl } from './tools';

const Context = React.createContext({
    defaultTextParams: {
        color: 'rgba(251,220,220,1)',
        font: '',
        // font: "'Ubuntu', sans-serif",
        fontSize: '16',
        fontStyle: 'normal',
        fontWeight: 'normal',
        horizontalAlign: 'center',
        textDecoration: 'initial',
        textValue: '',
        verticalAlign: 'middle',
    },
    defaultStyle: {
        borderRadius: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        pageWidth: 260,
    },
    defaultAuthParams: {
        authBackgroundColor: 'rgba(51, 71, 80, 0.9)',
        authButtonBackgroundColor: 'rgba(51, 71, 80, 0.9)',
        authButtonText: 'ВОЙТИ В ИНТЕРНЕТ',
        authDisplayMode: false,
        authParams: {
            layersCount: 1,
            nextConnectionPeriod: 0,
            redirectUrl: 'http://google.com',
            sessionPeriod: 30,
            traficVolume: 500,
            authPeriod: 1440,
            layers: [
                [
                    {
                        auth_title: 'sms',
                        expiration_sms_period: 15552000,
                    },
                ],
            ],
        },
    },
    selectedBlock: undefined,
    pathToFiles: 'https://cms.wifizone.me',
    constructorSize: {
        width: 300,
        height: 636,
        borderWidth: 4,
    },
    cursorCoords: { x: 0, y: 0 },
    showCreateNewModal: false,
    showAuthOptionsModal: false,
    showImageUploadModal: false,
    showDeletePageModal: false,
    showImgPickerModal: {
        show: false,
        options: {},
    },
    showAuthParams: false,
    token: '',
    pageId: null,
    pageTitle: '',
    pageUUID: '',
    mainLocation: '',
    lang: 'ru',
    loginResponse: {},
    changedPages: {},
    tempAuthOptions: {},
    pages: [],
    media: {},
    blockTypes: [],
    palette: { themePrimary: '' },
    controlPanel: {
        accordion: {
            1: true,
            2: false,
            3: false,
            4: false,
            5: false,
        },
    },
    blockParams: {
        accordion: {
            1: false,
            2: false,
            3: false,
            4: false,
            5: false,
        },
    },
    pageParams: {
        accordion: {
            1: false,
            2: false,
        },
    },
    constructor: {},
    showSpinner: false,
    windowDimensions: {},
    AuthOptionsModalData: {},
    AuthControlData: {},
    AuthControl: generateInitialAuthControl(),
    globalOptionsLabelsCatalog: {
        sessionPeriod: {
            label: 'Максимальная длительность сессии (минуты)',
            type: 'number',
        },
        nextConnectionPeriod: {
            label: 'Длительность до следующего подключения (минуты)',
            type: 'number',
        },
        authPeriod: {
            label: 'Период запроса авторизации (минуты)',
            type: 'number',
        },
        traficVolume: {
            label: 'Максимальный объем трафика (мб - не более 4000)',
            type: 'number',
        },
        redirectUrl: {
            label: 'Ссылка редиректа',
            type: 'text',
        },
    },
});

export default Context;
