import React, { useContext, useEffect, useMemo } from 'react';
import Radio from '../../radio';
import Checkbox from '../../checkbox';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import HorizontalLeftIcon from '@material-ui/icons/FormatAlignLeft';
import HorizontalCenterIcon from '@material-ui/icons/FormatAlignCenter';
import HorizontalRightIcon from '@material-ui/icons/FormatAlignRight';
import VerticalAlignTop from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignCenter from '@material-ui/icons/VerticalAlignCenter';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import styled from 'styled-components/macro';
import Context from '../../../context';

import SmallColorPicker from './small_color_picker';

const TextEditor = React.memo(({ classes, textParams, ...props }) => {
    const { state, dispatch } = useContext(Context);
    const { selectedBlock, defaultTextParams, constructor } = state;

    useEffect(() => {
        if (textParams === undefined) {
            dispatch({
                type: 'fillDefaultTextParams',
                payload: { textParams: defaultTextParams, selectedBlock },
            });
        }
    }, [selectedBlock, textParams, constructor]);

    const onAlignChange = (formatType) => (e) => {
        dispatch({
            type: 'onFormatChange',
            payload: { formatType, value: e.target.value, selectedBlock },
        });
    };

    const onFormatChange = (formatType, value) => (e) => {
        dispatch({
            type: 'onFormatChange',
            payload: { formatType, value, selectedBlock },
        });
    };

    const onFontChange = (formatType) => (e) => {
        dispatch({
            type: 'onFormatChange',
            payload: { formatType, value: e.target.value, selectedBlock },
        });
    };

    const fonts = [
        ['Open Sans, sans-serif', 'Open Sans'],
        ['Open Sans Condensed, sans-serif', 'Open Sans Condensed'],
        ['Playfair Display, serif', 'Playfair Display'],
        // ["'Playfair Display SC', serif", "Playfair Display SC"],
        ['Roboto, sans-serif', 'Roboto'],
        // ["'Roboto Slab', serif", "Roboto Slab"],
        ['Ubuntu, sans-serif', 'Ubuntu'],
        // ["Helios, sans-serif", 'Helios']
    ];

    const StyledInput = withStyles({
        input: {
            padding: '10px',
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '5px',
        },
    })(Input);

    const StyledMenuItem = withStyles({
        root: {
            padding: '2px 10px',
        },
    })(MenuItem);

    // const StyledCheckBox = withStyles({
    //     root: {
    //         padding: '10px 5px',
    //     },
    // })(Checkbox);

    // const StyledSelect = withStyles({
    //     outlined: {
    //         marginLeft: '10px',
    //         width: '30%',
    //     },
    // })(Select);

    const generateFontSizes = (min = 8, max = 72, step = 1) => {
        const result = [];
        for (let i = min; i <= max; i = i + step) {
            result.push(
                <StyledMenuItem key={`generateFontSizes-${i}`} value={i}>
                    {i}
                </StyledMenuItem>
            );
        }
        return result;
    };

    const onChangeColor = (formatType, backgroundColor, selectedBlock) => {
        dispatch({
            type: 'onFormatChange',
            payload: { formatType, value: backgroundColor, selectedBlock },
        });
    };

    return useMemo(
        () =>
            textParams && (
                <Container key={'Container-vdsvvbwe'}>
                    <TextField
                        key={'ddd-vdsvvbwe'}
                        id="outlined-multiline-flexible"
                        label="Текст"
                        multiline
                        fullWidth
                        value={textParams.textValue}
                        onChange={onFontChange('textValue')}
                        margin="dense"
                        variant="outlined"
                    />
                    <Container1>
                        <SelectContainer width={'58%'}>
                            <Select
                                value={textParams ? textParams.font : fonts[0][0]}
                                onChange={onFontChange('font')}
                                input={<StyledInput fullWidth disableUnderline name="font" id="font-auto-width" />}
                                autoWidth
                            >
                                {fonts.map((item, i) => {
                                    return (
                                        <MenuItem key={`${item[0]}-${i}`} value={item[0]}>
                                            {item[1]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </SelectContainer>
                        <SelectContainer width={'20%'}>
                            <Select
                                value={Number(textParams.fontSize)}
                                onChange={onFontChange('fontSize')}
                                input={<StyledInput fullWidth disableUnderline name="fontSize" id="fontSize-auto-width" />}
                                autoWidth
                            >
                                {generateFontSizes()}
                            </Select>
                        </SelectContainer>
                        <SelectContainer width={'20%'}>
                            <SmallColorPicker color={textParams.color} onChangeColor={onChangeColor} />
                        </SelectContainer>
                    </Container1>

                    <HorizontalContainer>
                        <Checkbox
                            disableRipple
                            padding={'10px 5px'}
                            checked={textParams.fontWeight === 'bold'}
                            onChange={onFormatChange('fontWeight', textParams.fontWeight === 'bold' ? 'normal' : 'bold')}
                            icon={<FormatBold fontSize="small" />}
                            checkedIcon={<FormatBold color="secondary" fontSize="small" />}
                            value="bold"
                        />
                        <Checkbox
                            disableRipple
                            padding={'10px 5px'}
                            checked={textParams.fontStyle === 'italic'}
                            onChange={onFormatChange('fontStyle', textParams.fontStyle === 'italic' ? 'normal' : 'italic')}
                            icon={<FormatItalic fontSize="small" />}
                            checkedIcon={<FormatItalic color="secondary" fontSize="small" />}
                            value="italic"
                        />
                        <Checkbox
                            disableRipple
                            padding={'10px 5px'}
                            checked={textParams.textDecoration === 'underline'}
                            onChange={onFormatChange('textDecoration', textParams.textDecoration === 'underline' ? 'initial' : 'underline')}
                            icon={<FormatUnderlined fontSize="small" />}
                            checkedIcon={<FormatUnderlined color="secondary" fontSize="small" />}
                            value="underline"
                        />
                        <Radio
                            padding={'10px 5px'}
                            checked={textParams.horizontalAlign === 'left'}
                            onChange={onAlignChange('horizontalAlign')}
                            value="left"
                            color="default"
                            name="horizontalAlign"
                            icon={<HorizontalLeftIcon fontSize="small" />}
                            checkedIcon={<HorizontalLeftIcon color="secondary" fontSize="small" />}
                        />
                        <Radio
                            padding={'10px 5px'}
                            checked={textParams.horizontalAlign === 'center'}
                            onChange={onAlignChange('horizontalAlign')}
                            value="center"
                            color="default"
                            name="horizontalAlign"
                            icon={<HorizontalCenterIcon fontSize="small" />}
                            checkedIcon={<HorizontalCenterIcon color="secondary" fontSize="small" />}
                        />
                        <Radio
                            padding={'10px 5px'}
                            checked={textParams.horizontalAlign === 'right'}
                            onChange={onAlignChange('horizontalAlign')}
                            value="right"
                            color="default"
                            name="horizontalAlign"
                            icon={<HorizontalRightIcon fontSize="small" />}
                            checkedIcon={<HorizontalRightIcon color="secondary" fontSize="small" />}
                        />

                        <Radio
                            padding={'10px 5px'}
                            checked={textParams.verticalAlign === 'top'}
                            onChange={onAlignChange('verticalAlign')}
                            value="top"
                            color="default"
                            name="verticalAlign"
                            icon={<VerticalAlignTop fontSize="small" />}
                            checkedIcon={<VerticalAlignTop color="secondary" fontSize="small" />}
                        />
                        <Radio
                            padding={'10px 5px'}
                            checked={textParams.verticalAlign === 'middle'}
                            onChange={onAlignChange('verticalAlign')}
                            value="middle"
                            color="default"
                            name="verticalAlign"
                            icon={<VerticalAlignCenter fontSize="small" />}
                            checkedIcon={<VerticalAlignCenter color="secondary" fontSize="small" />}
                        />
                        <Radio
                            padding={'10px 5px'}
                            checked={textParams.verticalAlign === 'bottom'}
                            onChange={onAlignChange('verticalAlign')}
                            value="bottom"
                            color="default"
                            name="verticalAlign"
                            icon={<VerticalAlignBottom fontSize="small" />}
                            checkedIcon={<VerticalAlignBottom color="secondary" fontSize="small" />}
                        />
                    </HorizontalContainer>
                </Container>
            ),
        [textParams, onFontChange, fonts, onFormatChange, onAlignChange]
    );
});

export default TextEditor;

const Container = styled.div``;

const Container1 = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;
const SelectContainer = styled.div`
    width: ${(p) => p.width};
    height: 40px;
`;
const HorizontalContainer = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    margin-bottom: 5px;
    padding: ${(p) => p.padd};
`;
