import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import Switch from '../../switch';

const LevelSwitch = React.memo(({ auth_title, title, disabled, checked, level, radio, ...props }) => {
    const { t } = useTranslation();

    const handleChange = (auth_title) => (event) => {
        const isChecked = event.target.checked;
        props.onToggle(level, auth_title, radio, isChecked);
    };

    return (
        <SwitchContainer>
            <InnerContainer>
                <p>{t(title)}</p>
                <Switch disabled={disabled}  checked={checked} onChange={handleChange(auth_title)} value={auth_title} />
            </InnerContainer>
            {props.children}
        </SwitchContainer>
    );
});

export default LevelSwitch;

const SwitchContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    padding-left: 15px;
`;

const InnerContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    p {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 75%;
    }
`;
