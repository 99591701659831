import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../context';
import { useTranslation } from 'react-i18next';
import Block from './components/block';
import { blockSize, subBlockSize, isIndexesEqual, calculateSizes } from '../../tools';

const Constructor = React.memo(({ isPreview }) => {
    const { t, i18n } = useTranslation();

    const { state, dispatch } = useContext(Context);
    const { constructor, constructorSize, selectedBlock, showAuthParams } = state;
    const border = {
        width: constructorSize.borderWidth,
    };
    const bgMedia = constructor && constructor.media ? constructor.media[0] : undefined;

    const onMainBGClick = () => {
        !isPreview &&
            dispatch({
                type: 'onMainBGClick',
            });
    };

    return constructorSize && constructor && constructor !== null ? (
        <Container onClick={onMainBGClick}>
            <MainBG
                onClick={onMainBGClick}
                size={constructorSize}
                bgColor={constructor && constructor.backgroundColor}
                bgMedia={bgMedia}
                pathToFiles={state.pathToFiles}
                isPreview={isPreview}
            >
                <Wrapper isPreview={isPreview} showAuthParams={showAuthParams}>
                    {constructor.blocks &&
                        constructor.blocks.map((block, i) => {
                            const size = blockSize(constructorSize, block.prototype, border.width, block.style);
                            const index = { block: i, subBlock: undefined };
                            const zIndex = selectedBlock && isIndexesEqual(selectedBlock, index) ? 11 : 1;
                            return (
                                <BlockWrapper
                                    key={`${block.prototype.name}-${i}`}
                                    position={'relative'}
                                    prototype={block.prototype}
                                    parentPrototype={block.prototype}
                                    constructorSize={constructorSize}
                                    style={block.style}
                                >
                                    <Block
                                        interactive={true}
                                        marginBottom={5}
                                        border={border}
                                        position={'relative'}
                                        size={size}
                                        block={block}
                                        index={index}
                                        zIndex={zIndex}
                                        isPreview={isPreview}
                                        prototype={block.prototype}
                                        parentPrototype={block.prototype}
                                    />
                                    {block.subBlocks.map((subblock, j) => {
                                        const index = { block: i, subBlock: j };
                                        const zIndex = selectedBlock && isIndexesEqual(selectedBlock, index) ? 11 : 8;
                                        return (
                                            <BlockWrapper
                                                key={`${subblock.prototype.width}-${i}-${j}`}
                                                position={'absolute'}
                                                prototype={subblock.prototype}
                                                parentPrototype={block.prototype}
                                                constructorSize={constructorSize}
                                                style={subblock.style}
                                            >
                                                <Block
                                                    interactive={true}
                                                    border={border}
                                                    position={'absolute'}
                                                    size={subBlockSize(size, subblock.prototype, border.width, subblock.style)}
                                                    block={subblock}
                                                    index={index}
                                                    zIndex={zIndex}
                                                    isPreview={isPreview}
                                                    prototype={block.prototype}
                                                    parentPrototype={block.prototype}
                                                />
                                            </BlockWrapper>
                                        );
                                    })}
                                </BlockWrapper>
                            );
                        })}
                </Wrapper>
                <InternetButtonContainer show={showAuthParams}>
                    <InternetButton isPreview={isPreview} bgColor={constructor.authButtonBackgroundColor || 'rgba(51, 71, 80, 0.9)'}>
                        <p>{constructor.authButtonText || t('ВОЙТИ В ИНТЕРНЕТ')}</p>
                    </InternetButton>
                </InternetButtonContainer>
            </MainBG>
        </Container>
    ) : null;
});

export default Constructor;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;
    /* flex-direction: column; */
    /* align-items: center; */
    min-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: ${(p) => (p.isPreview ? '20px' : '3px')};
    padding-bottom: ${(p) => (p.showAuthParams ? '50px' : '0px')};
`;

const InternetButtonContainer = styled.div`
    display: ${(p) => (p.show ? 'block' : 'none')};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
`;

const InternetButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px !important;
    width: 100%;
    background-color: ${(p) => p.bgColor};
    border-bottom-right-radius: ${(p) => (p.isPreview ? '20px' : '3px')};
    border-bottom-left-radius: ${(p) => (p.isPreview ? '20px' : '3px')};

    p {
        color: #fff;
        font-size: 16px;
        padding: 0;
        margin: 0;
    }
`;

const BlockWrapper = styled.div.attrs(({ position, prototype, parentPrototype, constructorSize, style }) => {
    return calculateSizes(position, prototype, parentPrototype, constructorSize, style, true);
})`
    position: ${(p) => p.position + '!important'};
    /* background-color: rgba(255, 0, 0, 0.4); */
    left: ${(props) => props.left + '!important'};
    top: ${(props) => props.top + '!important'};
    width: ${(props) => props.width + '!important'};
    height: ${(props) => props.height + '!important'};
    padding-top: ${(props) => props.paddingTop + '!important'};
    padding-bottom: ${(props) => props.paddingBottom + '!important'};
    padding-left: ${(props) => props.paddingLeft + '!important'};
    padding-right: ${(props) => props.paddingRight + '!important'};
`;

const Container = styled.div`
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;
`;

const MainBG = styled.div`
    cursor: pointer;
    box-sizing: content-box;
    width: ${(p) => p.size.width + 'px'};
    height: ${(p) => p.size.height + 'px'};
    background-color: ${(p) => p.bgColor};
    background-image: ${(p) => (p.bgMedia && p.bgMedia.url ? 'url("' + p.pathToFiles + p.bgMedia.url + '")' : null)};
    background-size: ${(p) => (p.bgMedia && p.bgMedia.BGSize ? p.bgMedia.BGSize : 'cover')};
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border: ${(p) => (p.isPreview ? 'none' : '1px solid #777')};
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: ${(p) => (p.isPreview ? '20px' : '3px')};
`;
