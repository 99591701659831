import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Close from '@material-ui/icons/Close';

import Context from '../../../context';

const BlockControl = React.memo(({ interactive, position, dimBlock, isPreview }) => {
    const { state, dispatch } = useContext(Context);
    const { selectedBlock, constructor } = state;

    const onBlockMoveClick = direction => e => {
        e.stopPropagation();
        if (
            (direction === 'up' && selectedBlock.block <= 0) ||
            (direction === 'down' && selectedBlock.block >= constructor.blocks.length - 1)
        ) {
            return;
        } else {
            dispatch({
                type: 'onBlockMoveClick',
                payload: { selectedBlock, direction }
            });
        }
    };

    const onDeleteBlockClick = e => {
        e.stopPropagation();
        dispatch({
            type: 'onDeleteBlockClick',
            payload: { selectedBlock }
        });
    };

    return useMemo(
        () => (
            <BlockControlDiv
                position={position}
                dimBlock={dimBlock}
                interactive={interactive}
                selectedBlock={selectedBlock}
                isPreview={isPreview}
                className="BlockControl"
            >
                <ArrowDownward onClick={onBlockMoveClick('down')} fontSize="small" />
                <ArrowUpward onClick={onBlockMoveClick('up')} fontSize="small" />
                <Close onClick={onDeleteBlockClick} fontSize="small" />
            </BlockControlDiv>
        ),
        [dimBlock, interactive, isPreview, onBlockMoveClick, onDeleteBlockClick, position, selectedBlock]
    );
});

export default BlockControl;

const BlockControlDiv = styled.div`
    display: ${p =>
        p.position === 'relative' && !p.dimBlock && !p.isPreview && p.interactive && p.selectedBlock ? 'flex' : 'none'};
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.5);
`;
