import React, { useEffect, useReducer, useContext, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';
import cookie from 'react-cookies';
import SplitPane from 'react-split-pane';
import { parseQuery, API } from './tools';
import { convertWhiteLabelColor } from './colors';

import Context from './context';
import Reducer from './reducer';
import './main.css';

import ControlPanel from './components/conrtol_panel';
import Constructor from './components/constructor';
import Preview from './components/preview';
import Header from './components/header';
import CreateNewModal from './components/create_new_modal';
import ImageUploadModal from './components/image_upload_modal';
import DeletePageModal from './components/delete_page_modal';
import AuthOptionsModal from './components/auth_options_modal';
import ImgPickerModal from './components/img_picker_modal';
import SpinnerModal from './components/spinner_modal';

library.add(faStroopwafel);

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const App = React.memo(() => {
    const { t, i18n } = useTranslation();
    const initialState = useContext(Context);
    const [state, dispatch] = useReducer(Reducer, initialState);
    const { pathToFiles } = state;

    useEffect(() => {
        if (state?.loginResponse?.white_label_settings?.header_color) {
            const palette = {
                neutralLighterAlt: '#f8f8f8',
                neutralLighter: '#f4f4f4',
                neutralLight: '#eaeaea',
                neutralQuaternaryAlt: '#dadada',
                neutralQuaternary: '#d0d0d0',
                neutralTertiaryAlt: '#c8c8c8',
                neutralTertiary: '#b2b2b2',
                neutralSecondary: '#999999',
                neutralPrimaryAlt: '#808080',
                neutralPrimary: '#1c1c1c',
                neutralDark: '#4e4e4e',
                black: '#353535',
                white: '#ffffff',
                ...convertWhiteLabelColor(state.loginResponse.white_label_settings.header_color),
            };
            dispatch({
                type: 'setPalette',
                payload: { palette },
            });
        }
    }, [state.loginResponse]);

    useEffect(() => {
        // console.log(document.referrer);
        dispatch({
            type: 'setMainLocation',
            payload: { mainLocation: document.referrer },
        });
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // useEffect(() => {
    //     const query = parseQuery(window.location.search);
    //     const xToken = query.token ? query.token : cookie.load('xtoken');
    //     const pageId = query.page_id;
    //     const mainLocation = query.back_url;
    //     const lang = query.lang || 'en';
    //     i18n.changeLanguage(lang);
    //     if (!xToken || xToken === 'undefined') {
    //         window.location.replace('https://sso.wifizone.me/accounts/login?next=' + document.location.href, '_blank');
    //     }

    //     dispatch({
    //         type: 'setQueryParams',
    //         payload: { xToken, pageId, mainLocation, lang }
    //     });
    //     xToken && cookie.save('xtoken', xToken);
    //     window.history.pushState(null, null, window.location.href.split('?')[0]);
    // }, [i18n]);

    useEffect(() => {
        const query = parseQuery(window.location.search);
        const xToken = query.token ? query.token : cookie.load('xtoken');
        const pageId = query.page_id;
        const mainLocation = query.back_url;
        const lang = query.lang || 'en';
        i18n.changeLanguage(lang);
        if (!xToken || xToken === 'undefined') {
            window.location.replace('https://sso.wifizone.me/accounts/login?next=' + document.location.href, '_blank');
        }

        if (xToken) {
            API('https://admin.client.getshopster.net/accounts/current', xToken, 'setLoginResponse', dispatch);
            API(`${pathToFiles}/content/get_pages/?access_token=${xToken}`, undefined, 'setPages', dispatch).then((r) => {
                dispatch({
                    type: 'setQueryParams',
                    payload: { xToken, pageId, mainLocation, lang },
                });
            });
            API(`${pathToFiles}/content/block_types/?access_token=${xToken}`, undefined, 'setBlockTypes', dispatch);
            API(`${pathToFiles}/content/getfiles/?access_token=${xToken}`, undefined, 'setMedia', dispatch);
        }
        xToken && cookie.save('xtoken', xToken);
        window.history.pushState(null, null, window.location.href.split('?')[0]);
    }, [i18n]);

    useEffect(() => {
        const { pages, pageId } = state;
        const filtered = pages.filter((item) => item.id === pageId);
        const json = pages.length !== 0 && filtered[0] ? JSON.parse(filtered[0].data) : undefined;
        dispatch({
            type: 'setConstructor',
            payload: { json },
        });
    }, [state.pages, state.pageId]);

    const setWindowDimensions = (dimensions) => {
        dispatch({
            type: 'setWindowDimensions',
            payload: dimensions,
        });
    };

    // console.log(state);

    return useMemo(
        () => (
            <Context.Provider value={{ state, dispatch }}>
                <SplitPane split="horizontal" minSize={50} allowResize={false}>
                    <Header />
                    <SplitPane split="vertical" minSize={320}>
                        <ControlPanel />
                        <SplitPane split="vertical" minSize={'50%'}>
                            <Constructor />
                            <Preview />
                        </SplitPane>
                    </SplitPane>
                </SplitPane>
                <ImageUploadModal />
                <DeletePageModal />
                <CreateNewModal />
                <AuthOptionsModal />
                <ImgPickerModal />
                <SpinnerModal />
            </Context.Provider>
        ),
        [state]
    );
});

export default App;
