import React from 'react';
import Radio from '@material-ui/core/Radio';
import styled from 'styled-components/macro';
import Context from '../context';

const MyRadio = React.memo((props) => {
    const { state } = React.useContext(Context);
    const { palette } = state;
    return <StyledRadio palette={palette} {...props} />;
});

export default MyRadio;

const StyledRadio = styled(Radio)`
    padding: ${(p) => {
        if (p.padding) {
            return p.padding + ' !important';
        }
    }};
    .MuiSvgIcon-root {
        color: ${(p) => {
            if (p.checked) {
                return p.palette.themePrimary + '!important';
            }
        }};
    }
`;
