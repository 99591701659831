import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import TextField from '@material-ui/core/TextField';

const WebLink = React.memo(({ url, ...props }) => {
    const { state, dispatch } = useContext(Context);
    const { selectedBlock } = state;

    const onWebLinkChange = e => {
        if (e.target.value === '' || e.target.value === 'http:/') {
            return;
        } else {
            dispatch({
                type: 'onWebLinkChange',
                payload: { selectedBlock, url: e.target.value }
            });
        }
    };

    return useMemo(
        () => (
            <Container>
                <TextField
                    key={'ddd-vdsvvbwe'}
                    id="outlined-multiline-flexible"
                    label="Web-Link"
                    // multiline
                    fullWidth
                    value={url}
                    onChange={onWebLinkChange}
                    margin="dense"
                    variant="outlined"
                />
            </Container>
        ),
        [onWebLinkChange, url]
    );
});

export default WebLink;

const Container = React.memo(styled.div`
    width: 100%;
    div {
        input {
            display: flex;
            align-items: center;
        }
    }
`);
