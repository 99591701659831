import React, { useContext } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import Input from '@material-ui/core/Input';

const BorderInput = React.memo(({ value, name, ...props }) => {
    const { state, dispatch } = useContext(Context);
    const { selectedBlock } = state;

    const cornersCatalog = {
        borderRadius: `Скругление (px)`,
        paddingTop: `Отступ сверху (px)`,
        paddingBottom: `Отступ снизу (px)`,
        paddingLeft: `Отступ слева (px)`,
        paddingRight: `Отступ справа (px)`
    };

    const onBorderInputChange = e => {
        dispatch({
            type: 'onBorderInputChange',
            payload: { name, value: e.target.value, selectedBlock }
        });
    };

    return (
        <InputContainer>
            <span>{cornersCatalog[name]}</span>
            <Input type="number" inputProps={{ min: 0 }} value={value} disableUnderline onChange={onBorderInputChange} />
        </InputContainer>
    );
});

export default BorderInput;

const InputContainer = React.memo(styled.div`
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: grid;
    grid-template-columns: 9fr 3fr;
    margin-bottom: 3px;
    span {
        display: flex;
        align-items: center;
        padding-left: 10px;
    }
`);
