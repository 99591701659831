import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import Context from '../../../context';
import BorderInput from './border_input';

const Paddings = React.memo(({ classes, style, ...props }) => {
    const { state, dispatch } = useContext(Context);
    const { selectedBlock, defaultStyle } = state;

    useEffect(() => {
        if (style === undefined) {
            dispatch({
                type: 'fillDefaultStyle',
                payload: { style: defaultStyle, selectedBlock }
            });
        }
    }, [defaultStyle, dispatch, selectedBlock, style]);

    return useMemo(
        () =>
            style && (
                <Container>
                    <BorderInput name={`borderRadius`} value={style.borderRadius} />
                    <BorderInput name={`paddingTop`} value={style.paddingTop} />
                    <BorderInput name={`paddingBottom`} value={style.paddingBottom} />
                    <BorderInput name={`paddingLeft`} value={style.paddingLeft} />
                    <BorderInput name={`paddingRight`} value={style.paddingRight} />
                </Container>
            ),
        [style]
    );
});

export default Paddings;

const Container = React.memo(styled.div``);
